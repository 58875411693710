import React from 'react'
import { Button, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { getFilterParams } from 'utils/getFilterParams'
import { getURLFromFilterParams } from 'utils/getURLFromFilterParams'
import { useHistory } from 'react-router-dom'
import { FilterFooter, FilterHeader, FilterStack } from 'chakra-lib/layout'
import { InputSearch } from 'chakra-lib'
import { FILTER_DOCUMENT_PARAMS } from 'config/document.config'

export const DocumentFilter = ({ params }) => {
  const { name } = useSelector((state) => state.document.filter)
  const history = useHistory()
  const countList = useSelector((state) => state.document.totalItems)

  const handleFilter = (param) => {
    const fieldName = Object.keys(param)[0]
    const filedValue = param[fieldName]
    const objFilterParams = getFilterParams(FILTER_DOCUMENT_PARAMS, params)
    const urlFilterParams = getURLFromFilterParams({
      ...objFilterParams,
      [fieldName]: filedValue,
    })
    history.push(`/documents${urlFilterParams}`)
  }

  const handleResetFilter = () => {
    history.push(`/documents`)
  }
  return (
    <>
      <FilterHeader>
        <Button width="100%" onClick={() => history.push(`/documents/new`)} colorScheme="orange">
          Создать документ
        </Button>
      </FilterHeader>
      <FilterStack>
        <InputSearch
          hideIcon
          label="Имя"
          name="name"
          value={name}
          onChange={(e) => handleFilter({ name: e })}
        />
      </FilterStack>
      <FilterFooter>
        <Button size="sm" width="100%" onClick={handleResetFilter}>
          Очистить фильтр
        </Button>
        <Text fontSize="0.8rem" color="gray.300" mt={2}>{`Найдено: ${countList}`}</Text>
      </FilterFooter>
    </>
  )
}
