import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { api } from 'api'
import useQuery from '../../useQuery'

const useSettingsFetch = () => {
  const toast = useToast()
  const { isError, isLoading, isSuccess, response, request } = useQuery(() =>
    api.settings.fetchOpenApi()
  )

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])

  return {
    data: response?.[0],
    getSettings: request,
    isLoadingFetch: isLoading,
    isSuccessFetch: isSuccess,
  }
}

export default useSettingsFetch
