import React, { useEffect, useState } from 'react'
import {
  Container,
  FilterFooter,
  FilterStack,
  FilterStackGroup,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside,
} from 'chakra-lib/layout'
import {
  InputDate,
  Select,
  Snippets,
  SnippetsHeading,
  SnippetsItem,
  SnippetsList,
} from 'chakra-lib'
import { HeadingItem, ItemIcon, ItemLink, ItemText } from 'chakra-lib/Snippets'
import useFetchReferralTransfer from './services/useFetchReferralTransfer'
import { SiSublimetext } from 'react-icons/si'
import { getDateTimeReadFormat, getDateTimeToSubmit } from 'utils/dateFormatting'
import { Box, Button, HStack, Stack, Text, useToast } from '@chakra-ui/react'
import { ActionButton } from 'components/ActionButton'
import useTransferProcess from './services/useTransferProcess'
import useTransferRefund from './services/useTransferRefund'

const initFilter = {
  transferStatus: '',
  createdStart: '',
  createdEnd: '',
  processedStart: '',
  processedEnd: '',
}

export const Transfer = () => {
  const toast = useToast()
  const { data, getReferralTransfer } = useFetchReferralTransfer()
  const { setProcess } = useTransferProcess()
  const { setRefund } = useTransferRefund()

  const [filter, setFilter] = useState(initFilter)

  const updateList = async () => {
    const dataPagination = {
      page: '0',
      size: '10000',
    }
    await getReferralTransfer({
      ...dataPagination,
      ...(filter.transferStatus !== '' && { transferStatus: filter.transferStatus }),
      ...(filter.createdStart !== '' && { createdStart: getDateTimeToSubmit(filter.createdStart) }),
      ...(filter.createdEnd !== '' && { createdEnd: getDateTimeToSubmit(filter.createdEnd) }),
      ...(filter.processedStart !== '' && {
        processedStart: getDateTimeToSubmit(filter.processedStart),
      }),
      ...(filter.processedEnd !== '' && { processedEnd: getDateTimeToSubmit(filter.processedEnd) }),
    })
  }

  const handleFilter = (value, field) => {
    setFilter((e) => ({
      ...e,
      [field]: value,
    }))
  }

  useEffect(() => {
    updateList()
  }, [filter])

  const handleProcessClick = (id) =>
    new Promise(async (resolve, reject) => {
      try {
        await setProcess(id)
        await updateList()
        toast({
          description: 'Успешно зачислено на счет',
          status: 'success',
        })
        resolve()
      } catch (e) {
      } finally {
        reject()
      }
    })

  const handleRefundClick = (id) =>
    new Promise(async (resolve, reject) => {
      try {
        await setRefund(id)
        await updateList()
        toast({
          description: 'Успешно отказано',
          status: 'success',
        })
        resolve()
      } catch (e) {
      } finally {
        reject()
      }
    })

  const handleResetFilter = () => {
    setFilter({ ...initFilter })
  }

  return (
    <Container>
      <SnippetsContainer indentTop={120}>
        <SnippetsContainerMain>
          <Snippets
            columns="42px 80px minmax(180px, 1fr) 180px 180px 200px 200px 200px 230px"
            itemWidth="1600px"
          >
            <SnippetsHeading>
              <HeadingItem />
              <HeadingItem>N</HeadingItem>
              <HeadingItem>Пользователь</HeadingItem>
              <HeadingItem>Бонусы</HeadingItem>
              <HeadingItem>Сумма, ₽</HeadingItem>
              <HeadingItem>Дата операции</HeadingItem>
              <HeadingItem>Дата запроса</HeadingItem>
              <HeadingItem>Статус</HeadingItem>
            </SnippetsHeading>
            <SnippetsList activePage={0} totalPages={0} data={data} keyField="eventCode">
              {(item) => {
                const status = {
                  '-1': 'Отклонен',
                  0: 'Не проведен',
                  1: 'Проведен',
                }

                return (
                  <SnippetsItem to={`/user/phone=${item?.customerId}`}>
                    <ItemIcon icon={SiSublimetext} />
                    <ItemLink>{item?.rowId}</ItemLink>
                    <ItemLink>{item?.customerId}</ItemLink>
                    <ItemText>{`${item?.amount}`}</ItemText>
                    <ItemText>{`${item?.amountRub}`}</ItemText>
                    <ItemText>{getDateTimeReadFormat(item?.processed)}</ItemText>
                    <ItemText>{getDateTimeReadFormat(item?.created)}</ItemText>
                    <ItemText>{status[String(item?.transferStatus)]}</ItemText>
                    <HStack spacing="10px">
                      <ActionButton
                        colorScheme="green"
                        isDisabled={item?.transferStatus === -1 || item?.transferStatus === 1}
                        onClick={() => handleProcessClick(item?.id)}
                      >
                        Зачислить
                      </ActionButton>
                      <ActionButton
                        isDisabled={item?.transferStatus === -1 || item?.transferStatus === 1}
                        onClick={() => handleRefundClick(item?.id)}
                      >
                        Отклонить
                      </ActionButton>
                    </HStack>
                  </SnippetsItem>
                )
              }}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <FilterStack>
            <Select
              name="transferStatus"
              label="Статус"
              isSearchable={false}
              placeholder="Выберите"
              value={filter.transferStatus}
              options={[
                {
                  value: '',
                  label: 'Все',
                },
                {
                  value: '0',
                  label: 'Не проведен',
                },
                {
                  value: '1',
                  label: 'Проведен',
                },
                {
                  value: '-1',
                  label: 'Отклонен',
                },
              ]}
              onChange={(e) => handleFilter(e.value, 'transferStatus')}
            />
            <FilterStackGroup>
              <InputDate
                value={filter.createdStart}
                showCurrentDate={false}
                name="createdStartFilter"
                placement="bottom"
                onChange={(e) => handleFilter(e, 'createdStart')}
                label="Дата запроса"
                placeholder="C"
              />
              <InputDate
                value={filter.createdEnd}
                showCurrentDate={false}
                name="createdEndFilter"
                placement="bottom"
                onChange={(e) => handleFilter(e, 'createdEnd')}
                placeholder="По"
              />
            </FilterStackGroup>
            <FilterStackGroup>
              <InputDate
                value={filter.processedStart}
                showCurrentDate={false}
                name="processedStartFilter"
                placement="bottom"
                onChange={(e) => handleFilter(e, 'processedStart')}
                label="Дата операции"
                placeholder="C"
              />
              <InputDate
                value={filter.processedEnd}
                showCurrentDate={false}
                name="processedEndFilter"
                placement="bottom"
                onChange={(e) => handleFilter(e, 'processedEnd')}
                placeholder="По"
              />
            </FilterStackGroup>
          </FilterStack>
          <FilterFooter pb="10px">
            <Stack spacing={2} width="100%">
              <Box>
                <Button size="sm" width="100%" onClick={handleResetFilter}>
                  Очистить фильтр
                </Button>
                <Text
                  fontSize="0.8rem"
                  color="gray.300"
                  mt={2}
                  textAlign="center"
                >{`Найдено: ${data?.length}`}</Text>
              </Box>
            </Stack>
          </FilterFooter>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  )
}
