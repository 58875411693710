import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { api } from 'api'
import useQuery from '../../useQuery'

const useSettingsUpdate = () => {
  const toast = useToast()
  const { isError, isLoading, isSuccess, request } = useQuery((data) =>
    api.settings.updateOpenApi(data)
  )

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Настройки успешно обновлены',
        status: 'success',
      })
    }
  }, [isSuccess])

  return {
    updateSettings: request,
    isLoadingUpdate: isLoading,
  }
}

export default useSettingsUpdate
