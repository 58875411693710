import axios from 'axios'
import { Storage } from 'utils/storage'

const TIMEOUT = 60 * 3000
axios.defaults.timeout = TIMEOUT

let attempts = 0

const setupAxiosInterceptors = (onUnauthenticated, onRefreshToken) => {
  const onRequestSuccess = (config) => {
    const token = Storage.local.get('authenticationToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }
  const onResponseSuccess = (response) => response
  const onResponseError = (err) => {
    const localRefreshToken = Storage.local.get('refreshToken')
    const isInvalidToken = err.response?.headers['www-authenticate']?.includes('invalid_token')
    const status = err?.status || err?.response.status
    const statusText = err.response?.statusText
    if (isInvalidToken && attempts < 8) {
      localRefreshToken && onRefreshToken()
      attempts++
      return Promise.reject(err)
    }
    if (status === 401 || statusText === 'Unauthorized') {
      onUnauthenticated()
    }
    attempts = 0
    return Promise.reject(err)
  }
  axios.interceptors.request.use(onRequestSuccess)
  axios.interceptors.response.use(onResponseSuccess, onResponseError)
}

export default setupAxiosInterceptors
