import React, { useEffect, useRef, useState } from 'react'
import { Button, HStack, Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDateReadFormat,
  getDateToSubmit,
  getDiffDateDays,
  getDiffDateHours,
  getRangeDateFromType,
} from 'utils/dateFormatting'
import { updateIncomingTransfers } from 'store/user.reducer'
import moment from 'moment'
import { AlertDialog, InputDate } from 'chakra-lib'

export const RangeDateModal = ({ label }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const cancelRef = useRef()
  const [fromDateInput, setFromDateInput] = useState('')
  const [toDateInput, setToDateInput] = useState('')
  const { fromDate, toDate, typeDate } = useSelector((state) => state.user.incomingTransfers)
  const isDisabledSubmit = fromDateInput === '' || toDateInput === ''
  const [from, to] = getRangeDateFromType(typeDate)
  const [errorMessage, setErrorMessage] = useState('')
  const onOpen = () => {
    setIsOpen(true)
  }
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setErrorMessage('')
    setFromDateInput(getDateReadFormat(fromDate !== '' ? fromDate : from))
    setToDateInput(getDateReadFormat(toDate !== '' ? toDate : to))
  }, [fromDate, toDate])
  useEffect(() => {
    if (fromDateInput === '' || toDateInput === '') {
      return
    }
    const fromDateToSubmit = getDateToSubmit(fromDateInput)
    const toDateToSubmit = getDateToSubmit(toDateInput)
    const minDate = getDateToSubmit('01.01.2020')
    const currentDate = moment().set({ h: 0, m: 0, s: 0 })
    const checkFromDateMin = getDiffDateDays(fromDateToSubmit, minDate)
    const checkFromDateMax = getDiffDateHours(currentDate, fromDateToSubmit)
    const checkToDateMin = getDiffDateDays(toDateToSubmit, minDate)
    const checkToDateMax = getDiffDateHours(currentDate, toDateToSubmit)
    const startMoment = moment(fromDateToSubmit ? fromDateToSubmit : undefined).set({
      h: 0,
      m: 0,
      s: 0,
    })
    const endMoment = moment(toDateToSubmit ? toDateToSubmit : undefined).set({ h: 0, m: 0, s: 0 })
    const diffDate = startMoment.diff(endMoment, 'hours')
    if (checkFromDateMin < 0) {
      setErrorMessage('"Дата с" не должна быть меньше 01.01.2020')
      return
    }
    if (checkFromDateMax < 0) {
      setErrorMessage('"Дата с" не должна быть больше текущего дня')
      return
    }
    if (checkToDateMin < 0) {
      setErrorMessage('"Дата по" не должна быть меньше 01.01.2020')
      return
    }
    if (checkToDateMax < 0) {
      setErrorMessage('"Дата по" не должна быть больше текущего дня')
      return
    }
    if (diffDate > 0) {
      setErrorMessage('"Дата с" больше "Дата по"')
      return
    }
    setErrorMessage('')
  }, [fromDateInput, toDateInput])
  const onClose = () => setIsOpen(false)
  const handleSubmit = () => {
    dispatch(
      updateIncomingTransfers({
        fromDate: getDateToSubmit(fromDateInput),
        toDate: getDateToSubmit(toDateInput),
        typeDate: '',
      })
    )
    onClose()
  }
  return (
    <>
      <Button size="sm" width="198px" onClick={onOpen}>
        {label}
      </Button>
      <AlertDialog
        title="Выберите даты"
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
        footer={
          <>
            <Button onClick={onClose}>Отменить</Button>
            <Button
              //ref={cancelRef}
              colorScheme="orange"
              onClick={handleSubmit}
              ml={3}
              isDisabled={isDisabledSubmit || errorMessage}
            >
              Сохранить
            </Button>
          </>
        }
      >
        <HStack spacing={3} mb={4}>
          <InputDate
            hideClearButton
            value={fromDateInput}
            showCurrentDate={false}
            name="fromDate"
            placement="bottom"
            onChange={(e) => setFromDateInput(e)}
            label="Дата c"
            placeholder="01.01.2020"
          />
          <InputDate
            hideClearButton
            value={toDateInput}
            showCurrentDate={false}
            name="toDate"
            placement="bottom"
            onChange={(e) => setToDateInput(e)}
            label="Дата по"
            placeholder="01.01.2020"
          />
        </HStack>
        <Text fontSize="0.9rem" color="red.400">
          {errorMessage !== '' && errorMessage}
        </Text>
      </AlertDialog>
    </>
  )
}
