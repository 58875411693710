import { FaArrowAltCircleRight, IoCard } from 'react-icons/all'

export const TYPE_PAYMENT_STATE_LABEL = {
  ACCEPTED: 'Принят в обработку',
  PAY: 'В обработке (банк)',
  ACQUIRING: 'В обработке (процессинг)',
  FINISHED: 'Проведен',
  REJECTED_BY_PROVIDER: 'Отклонен (банк)',
  ROLLBACK: 'Отменен',
  ROLLBACK_WITH_REFUND: 'Отменен (с возвратом)',
  NOT_ACQUIRED: 'Отклонен (процессинг)',
  MANUAL_CHECK: 'На проверке',
  UNKNOWN: 'Внутренняя ошибка',
  NEED_CANCEL_PAYMENT_IN_SOVCOM: 'Ошибка проведения (банк)',
  PREPARED: 'Предварительно одобренный',
  PROCESSING: 'В обработке',
  APPROVED_BY_BANK: 'Проведен',
  REVERSING_BY_BANK_FAILED: 'Ошибка отмены (РБС)',
  PENDING_BANK_APPROVAL: 'Ожидает подтверждения (РБС)',
  APPROVED_BY_PC: 'обработан (ПЦ)',
  INITIATED: 'Не обрабатывался',
  CANCELED_BY_BANK: 'Отклонен (РБС)',
  REVERSE_REQUESTED: 'В обработке на отмену',
  CANCELING: 'В обработке на отмену',
  REVERSING_BY_BANK: 'В обработке на отмену (РБС)',
  REVERSED_BY_BANK: 'В обработке на отмену (ПЦ)',
  REVERSED: 'Отменен',
  CANCELING_BY_BANK: 'отменен (РБС)',
  CANCELING_BY_BANK_FAILED: 'ошибка отмены (РБС)',
  CANCELING_FAILED: 'Ошибка отмены (ПЦ)',
  MANUAL_SENDING_REQUIRED: 'Ручной разбор',
}

export const TYPE_PAYMENT_STATE_COLOR = {
  ACCEPTED: 'green',
  PAY: 'orange',
  ACQUIRING: 'orange',
  FINISHED: 'green',
  REJECTED_BY_PROVIDER: 'red',
  ROLLBACK: 'red',
  ROLLBACK_WITH_REFUND: 'red',
  NOT_ACQUIRED: 'red',
  MANUAL_CHECK: 'gray',
  UNKNOWN: 'red',
  NEED_CANCEL_PAYMENT_IN_SOVCOM: 'red',
  PREPARED: 'orange',
}

export const TYPE_PAYMENT_LABEL = {
  RF_RA_TRANSFER: 'Переводы из РФ в РА',
  WITHDRAW_TO_CARD: 'Перевод на карту',
  RF_RA_TRANSFER_TO_CARD: 'Перевод на карту РА',
}

export const TYPE_PAYMENT_ICON = {
  RF_RA_TRANSFER: FaArrowAltCircleRight,
  UNDEFINED_TYPE: IoCard,
  WITHDRAW_TO_CARD: IoCard,
  RF_RA_TRANSFER_TO_CARD: IoCard,
}

export const TYPE_PAYMENT_COLOR = {
  RF_RA_TRANSFER: 'green.400',
  UNDEFINED_TYPE: 'orange.400',
  WITHDRAW_TO_CARD: 'orange.400',
  RF_RA_TRANSFER_TO_CARD: 'green.400',
}

export const TYPE_PAYMENT_SOURCE_LABEL = {
  CARD: 'Карта',
  ACCOUNT: 'Аккаунт',
  UNDEFINED_TYPE: 'Неопределен',
}
