import { Box, Button, Center, Flex, Spinner } from '@chakra-ui/react'
import empty from './empty-image.svg'
import { ImageCropper } from 'components/ImageCropper'

export const IconUploader = ({
  value,
  isLoading,
  isOpen,
  onOpen,
  onClose,
  optionsCompress,
  optionsCropper,
  optionsCroppedCanvas,
  isInvalid,
  onUploaderChange,
  onDelete,
}) => {
  const handleCropperChange = (e) => {
    onUploaderChange && onUploaderChange(e)
  }

  return (
    <>
      <Box>
        <Flex
          alignItems="left"
          flexDirection="column"
          minH="40px"
          height="auto"
          py="0.8rem"
          maxW="fit-content"
          flex={1}
        >
          <Flex
            onClick={onOpen}
            boxSize="220px"
            borderRadius="10px"
            overflow="hidden"
            cursor="pointer"
            position="relative"
            flex="none"
            backgroundColor="gray.100"
            transition="0.4s"
            zIndex={5}
            backgroundImage={empty}
            borderWidth={1}
            borderColor="transparent"
            {...(isInvalid && {
              borderColor: '#E53E3E',
              boxShadow: '0 0 0 1px #e53e3e',
            })}
            _hover={{
              borderColor: 'blue.400',
              boxShadow: 'none',
            }}
          >
            <Flex
              flex={1}
              backgroundImage={value}
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              transition="0.4s"
              opacity={value === '' ? 0 : 1}
            />
            {isLoading && (
              <Center position="absolute" left={0} top={0} width="100%" height="100%">
                <Spinner color="blackAlpha.400" />
              </Center>
            )}
          </Flex>
          {value && (
            <Button size="xs" mt="20px" onClick={onDelete}>
              Удалить картинку
            </Button>
          )}
        </Flex>
      </Box>
      <ImageCropper
        isCropRequired
        isOpen={isOpen}
        onClose={onClose}
        onChange={handleCropperChange}
        optionsCompress={optionsCompress}
        optionsCropper={optionsCropper}
        optionsCroppedCanvas={optionsCroppedCanvas}
      />
    </>
  )
}
