import React, { useState } from 'react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { TextArea } from 'chakra-lib'
import useDisableUser from './services/useDisableUser'
import { getUserItem } from 'store/user.reducer'
import { useDispatch } from 'react-redux'

export const DisableUserModal = ({ userId }) => {
  const dispatch = useDispatch()
  const { isOpen, onClose } = useDisclosure()
  const { setDisable, isLoading } = useDisableUser(userId)
  const [commentValue, setCommentValue] = useState('')

  const handleSubmit = async () => {
    await setDisable()
    await dispatch(getUserItem(userId))
    onClose()
  }

  return (
    <>
      <Button
        width="100%"
        onClick={handleSubmit}
        colorScheme="red"
        variant="outline"
        isLoading={isLoading}
      >
        Заблокировать
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Подтверждение идентификации</ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <TextArea
              value={commentValue}
              onChange={(e) => setCommentValue(e.target.value)}
              placeholder="Введите комментарий"
            />
            <Box pt="30px" pb="20px">
              <Button colorScheme="orange" isLoading={isLoading} onClick={handleSubmit}>
                Разблокировать
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
