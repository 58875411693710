import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { Form } from 'components/Form'
import { FormUploadImage } from 'components/FormUploadImage'
import { SelectForm } from 'chakra-lib'
import useUploadScans from './services/useUploadScans'
import * as Yup from 'yup'
import { getUserItem } from 'store/user.reducer'
import { useDispatch, useSelector } from 'react-redux'

const optionsRussia = [
  {
    label: 'Фото из паспорта РФ (загруженное поддержкой)',
    value: 'RUSSIA_PHOTO_FROM_PASSPORT_SUPPORT',
  },
  {
    label: 'Селфи (загруженное поддержкой)',
    value: 'SELFIE_WITH_PASSPORT_SUPPORT',
  },
  {
    label: 'Первая страница паспорта (загруженное поддержкой)',
    value: 'PASSPORT_FIRST_PAGE_SUPPORT',
  },
]

const optionsArmenia = [
  {
    label: 'Фото из паспорта Армении (загруженное поддержкой)',
    value: 'ARMENIA_PHOTO_FROM_PASSPORT_SUPPORT',
  },
  {
    label: 'Селфи (загруженное поддержкой)',
    value: 'ARMENIA_SELFIE_WITH_PASSPORT_SUPPORT',
  },
  {
    label: 'Первая страница паспорта Армении (загруженное поддержкой)',
    value: 'ARMENIA_PASSPORT_FIRST_PAGE_SUPPORT',
  },
  {
    label: 'Фото в системе IDbank (загруженное поддержкой)',
    value: 'ARMENIA_PHOTO_FROM_IDBANK_SUPPORT',
  },
]

const formSchema = Yup.object({
  scanType: Yup.string().required('Заполните поле'),
  image: Yup.string().required('Заполните поле'),
})

export const UploadImageModal = ({ userId }) => {
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isLoading, isSuccess, uploadScans } = useUploadScans()
  const item = useSelector((state) => state.user.item)

  const handleForm = async (values) => {
    const image = JSON.parse(values.image)
    const scanString = image.uri.split(',')[1]
    const dataRequest = {
      scanString,
      scanType: values.scanType,
      userId: Number(userId),
    }
    await uploadScans(dataRequest)
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(getUserItem(userId))
      onClose()
    }
  }, [isSuccess])

  return (
    <>
      <Button colorScheme="orange" size="sm" onClick={onOpen}>
        Загрузить фотографию
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Загрузка фототографии</ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={formSchema}
              initialValues={{
                image: '',
                scanType: '',
              }}
              onSubmit={handleForm}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Stack spacing={4}>
                    <Field
                      name="image"
                      label="Изображение"
                      component={FormUploadImage}
                      colorScheme="orange"
                      isDisabled={isLoading}
                      optionsCropper={{
                        //aspectRatio: 1,
                        maxCropBoxHeight: 1000,
                        maxCropBoxWidth: 1000,
                        minCropBoxHeight: 260,
                        minCropBoxWidth: 260,
                      }}
                      optionsCompress={{
                        maxWidth: 1200,
                        maxHeight: 1200,
                      }}
                      optionsCroppedCanvas={{
                        maxWidth: 1200,
                        maxHeight: 1200,
                      }}
                    />
                    <Field
                      name="scanType"
                      label="Тип"
                      component={SelectForm}
                      placeholder="Выберите ..."
                      options={item?.identityCountry === 'RUSSIA' ? optionsRussia : optionsArmenia}
                    />
                  </Stack>
                  <Box pt="30px" pb="20px">
                    <Button type="submit" colorScheme="orange" isLoading={isLoading}>
                      Загрузить
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
