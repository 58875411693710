export const TYPE_USER_STATE_LABEL = {
  FAILED_DUE_BLACKLISTED: 'В черном списке',
  FAILED_DUE_INVALID_PASSPORT: 'Невалидный паспорт',
  FULL: 'Упрощенная идентификация',
  FULL_DOSSIER: 'Полная идентификация',
  READY: 'В ожидании',
  UNIDENTIFIED: 'Нет',
  FAILED: 'Ошибка',
  SUPPORT_REVIEW: 'Проверка данных',
}

export const TYPE_USER_STATE_COLOR = {
  FULL: 'purple',
  FULL_DOSSIER: 'green',
  UNIDENTIFIED: 'gray',
  READY: 'gray',
  FAILED: 'red',
  SUPPORT_REVIEW: 'orange',
}

export const TYPE_USER_COUNTRY = {
  RUSSIA: 'Россия',
  ARMENIA: 'Армения',
}

export const TYPE_USER_PLATFORM = {
  ANDROID: 'Android',
  IPHONE: 'iPhone',
}

export const ROLES = {
  user: 'USER',
  admin: 'ADMIN',
  marketing: 'MARKETING',
}

export const ROLES_LABEL = {
  [ROLES.user]: 'Пользователь',
  [ROLES.admin]: 'Администратор',
  [ROLES.marketing]: 'Маркетинг',
}

export const TYPE_ENABLED_LABEL = {
  true: 'Активен',
  false: 'Заблокирован',
}

export const TYPE_ENABLED_COLOR = {
  true: 'blue',
  false: 'red',
}
