import React, { useEffect } from 'react'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form,
  FormHeading,
  FormSection,
  HeadingContainer,
} from 'chakra-lib/layout'
import { CheckboxForm, Heading, InputForm } from 'chakra-lib'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import useUserItemFetch from './services/useUserItemFetch'
import useUserItemCreate from './services/useUserItemCreate'
import { Password } from './components/Password/Password'
import { Role } from './components/Role/Role'
import useUserItemUpdate from './services/useUserItemUpdate'
import { Delete } from './components/Delete/Delete'

const formSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
})

export const AccessUpdate = () => {
  const { id } = useParams()
  const isNew = !id
  const history = useHistory()

  const { data, fetchItem, isLoadingFetchItem } = useUserItemFetch(id)
  const { createItem, isLoadingCreateItem, isSuccessCreateItem } = useUserItemCreate()
  const { updateItem, isLoadingUpdateItem } = useUserItemUpdate(id)

  const isLoading = isLoadingFetchItem || isLoadingCreateItem || isLoadingUpdateItem
  const isReadOnly = id === 'aa5ecb7e-f037-4a8d-a185-f8ed1890fefb'

  const handleBack = () => {
    history.goBack()
  }

  const handleForm = async (values) => {
    if (isNew) {
      await createItem(values)
    } else {
      await updateItem(values)
      fetchItem()
    }
    //handleBack();
  }

  useEffect(() => {
    if (isSuccessCreateItem) {
      handleBack()
    }
  }, [isSuccessCreateItem])

  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={70}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>{isNew ? 'Создание пользователя' : 'Обновление пользователя'}</Heading>
              {!isNew && <Delete id={id} isReadOnly={isReadOnly} />}
            </HeadingContainer>
            <Formik
              initialValues={{
                username: data?.username,
                enabled: data?.enabled,
              }}
              enableReinitialize={true}
              onSubmit={handleForm}
              validationSchema={formSchema}
            >
              {(props) => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="orange"
                      isDisabled={!props.dirty}
                      isLoading={isLoading}
                    >
                      {isNew ? 'Создать' : 'Обновить'}
                    </Button>
                  }
                >
                  <FormSection>
                    <Field
                      name="username"
                      component={InputForm}
                      label="Логин"
                      isDisabled={isLoading || isReadOnly}
                    />
                    <Field
                      name="enabled"
                      component={CheckboxForm}
                      label="Активен"
                      isDisabled={isLoading || isReadOnly}
                    />
                  </FormSection>
                  {!isNew && (
                    <>
                      <FormSection>
                        <FormHeading>Пароль</FormHeading>
                        <Password id={id} />
                      </FormSection>
                      {!isReadOnly && (
                        <FormSection>
                          <FormHeading>Роли</FormHeading>
                          <Role id={id} />
                        </FormSection>
                      )}
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}
