import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Box, Spinner, Text } from '@chakra-ui/react'
import { resetAuth } from 'store/authentication.reducer'

export const Logout = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(resetAuth())
  }, [isAuthenticated])

  return isAuthenticated ? (
    <>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignContent="center"
        alignItems="center"
        padding="60px"
      >
        <Text>Загрузка...</Text>
        <Spinner color="blue.500" size="md" marginTop="20px" />
      </Box>
    </>
  ) : (
    <Redirect to="/" />
  )
}
