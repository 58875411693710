import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Flex, Icon, Spinner, Text } from '@chakra-ui/react'
import empty from './empty-image.svg'
import { BsCardImage } from 'react-icons/bs'

export const DropZoneImage = ({
  uploadedImage,
  onUpload,
  onError,
  maxSize = 1048576,
  maxFiles,
  multiple,
  height = '160px',
  width = '100%',
}) => {
  const [fullSize, setFullSize] = useState()
  const loadingUploadImage = false
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxSize: maxSize,
    maxFiles: maxFiles,
    multiple: multiple,
    onDropAccepted: (e) => {
      setFullSize(false)
      onUpload(e)
    },
    onDropRejected: (e) => {
      setFullSize(false)
      onError && onError(e)
    },
    //onDrop: onUpload
  })
  return (
    <section className="dropzone-input">
      <Box
        width={width}
        height={height}
        borderWidth={1}
        borderRadius={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        transition="0.2s"
        outline="none"
        cursor="pointer"
        borderColor={fullSize ? 'blue.300' : 'gray.200'}
        overflow="hidden"
        {...getRootProps({ className: 'dropzone' })}
        _hover={{
          borderColor: 'blue.300',
        }}
        {...(uploadedImage && {
          backgroundImage: `url(${empty})`,
        })}
      >
        <input {...getInputProps()} />
        {uploadedImage && (
          <Flex
            height={height}
            width="100%"
            backgroundImage={`url(${uploadedImage})`}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
          />
        )}
        {!uploadedImage && (
          <Flex flexDirection="column" maxW="300px" alignItems="center" textAlign="center">
            <Icon as={BsCardImage} boxSize="80px" color="gray.300" mb={3} />
            <Text fontSize="0.83rem" color="gray.400">
              Перетащите сюда картинку или щелкните, чтобы выбрать
            </Text>
          </Flex>
        )}
        {loadingUploadImage && <Spinner color="blue.500" size="sm" marginRight="10px" />}
      </Box>
    </section>
  )
}
