import { Button } from '@chakra-ui/react'
import { useState } from 'react'

export const ActionButton = ({ onClick, children, ...props }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async (e) => {
    try {
      e.stopPropagation()
      e.preventDefault()
      setIsLoading(true)
      await onClick()
    } catch (e) {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button size="sm" isLoading={isLoading} onClick={handleClick} {...props}>
      {children}
    </Button>
  )
}
