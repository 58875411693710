import React from 'react'
import { Flex, Text } from '@chakra-ui/react'

export const FormFooter = ({ children, isError }) => {
  return (
    <Flex borderTopWidth={1} paddingTop={8} marginTop={8}>
      {children}
      {isError && (
        <Flex alignItems="center" color="red.500" padding="0 20px" fontSize="0.87rem">
          <Text>Есть ошибки в форме</Text>
        </Flex>
      )}
    </Flex>
  )
}
