import axios from 'axios'
import { API_URL, SERVER } from 'config/api'

const ENTITY = 'payments'

export const payments = Object.freeze({
  async fetchPaymentsList(data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/search`, data, {
      transformResponse: [(data) => data],
    })
    return response.data
  },
  async competePayment(id, data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/${id}/completePayment`, data)
    return response.data
  },
  async fetchSettings() {
    const response = await axios.get(`${SERVER}${API_URL}/${ENTITY}/settings`)
    return response.data
  },
  async fetchHistory(id) {
    const response = await axios.get(
      `${SERVER}${API_URL}/${ENTITY}/${id}/paymentStateHistory?page=0&size=1000`
    )
    return response.data
  },
  async updateSettings(id, data) {
    const response = await axios.put(`${SERVER}${API_URL}/${ENTITY}/settings/${id}`, data)
    return response.data
  },
})
