import React, { useEffect, useState } from 'react'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form,
  FormGrid,
  FormHeading,
  FormSection,
  HeadingContainer,
} from 'chakra-lib/layout'
import { CheckboxForm, Heading, InputForm, TextAreaForm } from 'chakra-lib'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Flex, useToast } from '@chakra-ui/react'
import { Field, FieldArray, Formik } from 'formik'
import {
  createDocument,
  getDocumentItem,
  resetDocumentItem,
  updateDocument,
} from 'store/document.reducer'
import { isEmpty } from 'lodash'
import { DOCUMENT_LOCALIZATION } from 'config/document.config'
import { RadioButton } from 'components/RadioButton'
import { TYPE_LOCALIZATION_MODEL } from 'model/localization.model'
import * as Yup from 'yup'
import { Delete } from './components/Delete/Delete'

const formSchema = Yup.object().shape({
  order: Yup.string().required('Required'),
})

export const DocumentUpdate = () => {
  const { id } = useParams()
  const toast = useToast()
  const isNew = !id
  const history = useHistory()
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const item = useSelector((state) => state.document.item)
  const loading = useSelector((state) => state.document.loading)
  const handleBack = () => {
    history.goBack()
  }
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isNew) {
      dispatch(resetDocumentItem())
    } else {
      dispatch(getDocumentItem(id))
    }
    return () => {
      dispatch(resetDocumentItem())
    }
  }, [])
  const getInitialForm = () => {
    let documentCollection = []
    DOCUMENT_LOCALIZATION.forEach((lang) => {
      const nameItem = item?.names?.find((n) => n.localization === lang)
      const bodyItem = item?.bodies?.find((n) => n.localization === lang)
      documentCollection.push({
        name: !isEmpty(nameItem) ? nameItem.text : '',
        body: !isEmpty(bodyItem) ? bodyItem.text : '',
        idName: !isEmpty(nameItem) ? nameItem.id : 0,
        idBody: !isEmpty(bodyItem) ? bodyItem.id : 0,
        localization: lang,
      })
    })
    return documentCollection
  }
  const getSubmitForm = (list = []) => {
    let names = []
    let bodies = []
    list.forEach((itemDoc) => {
      names.push({
        localization: itemDoc.localization,
        text: itemDoc.name,
        id: itemDoc.idName === 0 ? undefined : itemDoc.idName,
      })
      bodies.push({
        localization: itemDoc.localization,
        text: itemDoc.body,
        id: itemDoc.idBody === 0 ? undefined : itemDoc.idBody,
      })
    })
    return {
      names,
      bodies,
    }
  }
  const handleForm = async (values) => {
    try {
      const dataRequest = {
        ...item,
        needUpdate: values.needUpdate,
        order: Number(values.order),
        type: values.type !== '' ? values.type : undefined,
        ...getSubmitForm(values.list),
      }
      if (isNew) {
        await dispatch(createDocument(dataRequest))
        toast({
          description: `Документ успешно создан`,
          variant: 'solid',
          status: 'success',
        })
        handleBack()
      } else {
        console.log(dataRequest)
        await dispatch(updateDocument(dataRequest))
        toast({
          description: `Документ успешно обновлен`,
          variant: 'solid',
          status: 'success',
        })
        handleBack()
      }
    } catch (err) {
      if (err.response.status !== 400 && err.response.status !== 401) {
        toast({
          description: `Ошибка загрузки. Код ${err.response.status}`,
          variant: 'solid',
          status: 'error',
        })
      }
    }
  }
  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={70}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>{isNew ? 'Создание документа' : 'Обновление документа'}</Heading>
              {!isNew && <Delete id={id} />}
            </HeadingContainer>
            <Formik
              initialValues={{
                list: getInitialForm(),
                needUpdate: Boolean(item.needUpdate),
                order: item.order !== undefined ? String(item.order) : '0',
                type: item?.type ? item?.type : '',
              }}
              enableReinitialize={true}
              onSubmit={handleForm}
              validationSchema={formSchema}
            >
              {(props) => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="orange"
                      isDisabled={!props.dirty}
                      isLoading={loading}
                    >
                      {isNew ? 'Создать' : 'Обновить'}
                    </Button>
                  }
                >
                  <Flex mb="30px">
                    <FieldArray
                      name="list"
                      render={() => (
                        <>
                          {props.values.list &&
                            props.values.list.length > 0 &&
                            props.values.list.map((friend, index) => (
                              <React.Fragment key={index}>
                                <RadioButton
                                  isActive={activeIndex === index}
                                  onClick={() => setActiveIndex(index)}
                                  mr={2}
                                >
                                  {TYPE_LOCALIZATION_MODEL[friend.localization] ||
                                    friend.localization}
                                </RadioButton>
                              </React.Fragment>
                            ))}
                        </>
                      )}
                    />
                  </Flex>
                  <FormSection>
                    <Field
                      name={`list[${activeIndex}].name`}
                      component={InputForm}
                      label="Название"
                    />
                    <Field
                      name={`list[${activeIndex}].body`}
                      component={TextAreaForm}
                      minHeight="400px"
                      label="Содержимое"
                    />
                  </FormSection>
                  <FormSection>
                    <FormHeading>Дополнительно</FormHeading>
                    <Field name="type" component={InputForm} label="Тип" isDisabled={loading} />
                    <FormGrid columns={2}>
                      <Field
                        type="number"
                        name="order"
                        component={InputForm}
                        label="Сортировка"
                        isDisabled={loading}
                      />
                    </FormGrid>
                    <Field name="needUpdate" label="Требует обновления" component={CheckboxForm} />
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}
