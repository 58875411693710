import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form,
  FormSection,
} from 'chakra-lib/layout'
import { Field, Formik } from 'formik'
import { Button } from '@chakra-ui/react'
import useFetchSettings from './services/useFetchSettings'
import useUpdateSettings from './services/useUpdateSettings'
import { SwitchForm } from 'components/SwitchForm'

export const Settings = () => {
  const { dataSettings, isLoadingFetchSettings } = useFetchSettings()
  const { updateSettings, isLoadingUpdate } = useUpdateSettings()

  const isLoading = isLoadingFetchSettings || isLoadingUpdate

  const handleForm = async (values) => {
    const dataRequest = {
      identityRAOn: values?.identityRAOn ? 1 : 0,
      identityRFOn: values?.identityRFOn ? 1 : 0,
      outRARFCardOn: values?.outRARFCardOn ? 1 : 0,
      payRFRACardOn: values?.payRFRACardOn ? 1 : 0,
      payRFRATelOn: values?.payRFRATelOn ? 1 : 0,
      referralOn: values?.referralOn ? 1 : 0,
    }
    await updateSettings(dataRequest)
  }

  return (
    <Container>
      <CardContainer indentTop={120} isHideBack>
        <ContentContainer>
          <ContentContainerColumn>
            <Formik
              initialValues={{
                identityRAOn: dataSettings?.identityRAOn === 1,
                identityRFOn: dataSettings?.identityRFOn === 1,
                outRARFCardOn: dataSettings?.outRARFCardOn === 1,
                payRFRACardOn: dataSettings?.payRFRACardOn === 1,
                payRFRATelOn: dataSettings?.payRFRATelOn === 1,
                referralOn: dataSettings?.referralOn === 1,
              }}
              enableReinitialize={true}
              onSubmit={handleForm}
            >
              {(props) => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button type="submit" colorScheme="orange" isLoading={isLoading}>
                      Обновить
                    </Button>
                  }
                >
                  <FormSection>
                    <Field
                      size="lg"
                      name="payRFRATelOn"
                      component={SwitchForm}
                      label="Перевод РФ-РА по телефону"
                      isDisabled={isLoading}
                    />
                    <Field
                      size="lg"
                      name="payRFRACardOn"
                      component={SwitchForm}
                      label="Перевод РФ-РА на карту"
                      isDisabled={isLoading}
                    />
                    <Field
                      size="lg"
                      name="outRARFCardOn"
                      component={SwitchForm}
                      label="Перевод РА-РФ"
                      isDisabled={isLoading}
                    />
                    <Field
                      size="lg"
                      name="identityRAOn"
                      component={SwitchForm}
                      label="ИД РА"
                      isDisabled={isLoading}
                    />
                    <Field
                      size="lg"
                      name="identityRFOn"
                      component={SwitchForm}
                      label="ИД РФ"
                      isDisabled={isLoading}
                    />
                    <Field
                      size="lg"
                      name="referralOn"
                      component={SwitchForm}
                      label="Бонусная прграмма"
                      isDisabled={isLoading}
                    />
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}
