import React from 'react'
import {
  Box,
  Button,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import useLunaSettingsFetch from './services/useLunaSettingsFetch'
import { Form } from '../Form'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { InputForm } from 'chakra-lib'
import useLunaSettingsUpdate from './services/useLunaSettingsUpdate'

const formSchema = Yup.object({
  failedLivenessAttemptsLimit: Yup.number()
    .integer('invalid decimal')
    .min(0, 'Min')
    .required('Заполните поле'),
  similarityMinimumRate: Yup.number()
    .integer('invalid decimal')
    .max(100, 'Max')
    .min(0, 'Min')
    .required('Заполните поле'),
  similarityRate: Yup.number()
    .integer('invalid decimal')
    .max(100, 'Max')
    .min(0, 'Min')
    .required('Заполните поле'),
})

export const LunaSettings = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { getLunaSettings, isLoadingFetch, data } = useLunaSettingsFetch()
  const { updateLunaSettings, isLoadingUpdate } = useLunaSettingsUpdate()

  const handleOpen = async () => {
    onOpen()
    await getLunaSettings()
  }

  const handleForm = async (values) => {
    const dataRequest = {
      ...data,
      failedLivenessAttemptsLimit: Number(values.failedLivenessAttemptsLimit),
      similarityMinimumRate: Number(values.similarityMinimumRate) / 100,
      similarityRate: Number(values.similarityRate) / 100,
    }
    await updateLunaSettings(dataRequest)
    onClose()
  }

  return (
    <>
      <MenuItem onClick={handleOpen}>Ручная идентификация</MenuItem>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Настройка ручной идентификации</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={formSchema}
              initialValues={{
                failedLivenessAttemptsLimit: String(data?.failedLivenessAttemptsLimit) ?? 0,
                similarityMinimumRate: String(data?.similarityMinimumRate * 100) ?? 0,
                similarityRate: String(data?.similarityRate * 100) ?? '0',
              }}
              onSubmit={handleForm}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Stack spacing={4}>
                    <Field
                      name="failedLivenessAttemptsLimit"
                      label="Лимит попыток идентификаций пользователя"
                      component={InputForm}
                      placeholder="0"
                    />
                    <Field
                      name="similarityMinimumRate"
                      label="Минимальный % совпадения"
                      component={InputForm}
                      placeholder="0"
                      rightAddon="%"
                    />
                    <Field
                      name="similarityRate"
                      label="Максимальный % совпадения"
                      component={InputForm}
                      placeholder="0"
                      rightAddon="%"
                    />
                  </Stack>
                  <Box pt="30px" pb="20px">
                    <Button
                      type="submit"
                      colorScheme="orange"
                      isLoading={isLoadingUpdate || isLoadingFetch}
                    >
                      Сохранить
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
