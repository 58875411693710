import React, { useState } from 'react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { TextArea } from 'chakra-lib'
import useCompletePayment from './services/useCompletePayment'
import { useDispatch } from 'react-redux'
import { getPaymentsItem } from 'store/payments.reducer'

export const CompletePaymentModal = ({ id }) => {
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { competePayment, isLoading } = useCompletePayment(id)
  const [commentValue, setCommentValue] = useState('')

  const handleSubmit = async () => {
    const dataRequest = {
      retryComment: commentValue,
    }
    await competePayment(dataRequest)
    await dispatch(getPaymentsItem(id))
    onClose()
  }

  return (
    <>
      <Button width="100%" onClick={onOpen} colorScheme="green">
        Провести платеж
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Проведение платежа</ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <TextArea
              value={commentValue}
              onChange={(e) => setCommentValue(e.target.value)}
              placeholder="Введите комментарий"
            />
            <Box pt="30px" pb="20px">
              <Button colorScheme="orange" isLoading={isLoading} onClick={handleSubmit}>
                Подтвердить
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
