import React, { useState } from 'react'
import { Button, HStack, useToast } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getIncomingTransfers,
  resetIncomingTransfersList,
  updateIncomingTransfers,
} from 'store/user.reducer'
import { RangeDateModal } from '../RangeDateModal'
import {
  getDateReadFormat,
  getDateTimeReadFormat,
  getRangeDateFromType,
} from 'utils/dateFormatting'
import isEmpty from 'lodash/isEmpty'
import { IoDocumentText } from 'react-icons/io5'
import { LimitPopup } from '../limitPopup'
import {
  Modal,
  SegmentControl,
  Snippets,
  SnippetsHeading,
  SnippetsItem,
  SnippetsList,
} from 'chakra-lib'
import { HeadingItem, ItemIcon, ItemLink, ItemText } from 'chakra-lib/Snippets'
import { GridField, GridFieldItem } from 'chakra-lib/layout'

export const IncomingTransfers = () => {
  const dispatch = useDispatch()
  const toast = useToast()
  const item = useSelector((state) => state.user.item)
  const { fromDate, toDate, typeDate, list, loading, limit } = useSelector(
    (state) => state.user.incomingTransfers
  )
  const [from, to] = getRangeDateFromType(typeDate)
  const [transferId, setTransferId] = useState('')
  const transferItem = transferId !== '' && list.find((i) => i.docId === transferId)
  const handleSegmentControl = (e) => {
    dispatch(
      updateIncomingTransfers({
        fromDate: '',
        toDate: '',
        typeDate: e,
      })
    )
  }
  const handleModalOpen = (id) => {
    setTransferId(id)
  }
  const handleModalClose = () => {
    setTransferId('')
  }
  const handleGetList = async () => {
    try {
      if (!isEmpty(item)) {
        await dispatch(resetIncomingTransfersList())
        if (typeDate === '') {
          const result = await dispatch(
            getIncomingTransfers(item.id, {
              fromDate,
              toDate,
              limit,
            })
          )
          toast({
            description: `Найдено переводов: ${result.value.data.length}`,
            variant: 'solid',
            status: 'success',
          })
          return
        }
        if (from !== '' && to !== '') {
          const result = await dispatch(
            getIncomingTransfers(item.id, {
              fromDate: from,
              toDate: to,
              limit,
            })
          )
          toast({
            description: `Найдено переводов: ${result.value.data.length}`,
            variant: 'solid',
            status: 'success',
          })
        }
      }
    } catch (e) {
      if (e.response.status !== 400 && e.response.status !== 401) {
        toast({
          description: `Ошибка загрузки. Код ${e.response.status}`,
          variant: 'solid',
          status: 'error',
        })
      }
    }
  }

  return (
    <>
      <HStack spacing={3} mb="30px">
        <SegmentControl
          onChange={handleSegmentControl}
          width="300px"
          options={[
            { label: 'Неделя', value: 'week' },
            { label: 'Месяц', value: 'month' },
            { label: 'Год', value: 'year' },
          ]}
          value={typeDate}
        />
        <RangeDateModal
          label={
            typeDate !== ''
              ? `${getDateReadFormat(from)} – ${getDateReadFormat(to)}`
              : `${getDateReadFormat(fromDate)} – ${getDateReadFormat(toDate)}`
          }
        />
        <LimitPopup />
        <Button colorScheme="orange" size="sm" onClick={handleGetList}>
          Запросить
        </Button>
      </HStack>
      <Snippets
        columns="42px 1fr 180px 180px 220px"
        //itemWidth="1440px"
      >
        <SnippetsHeading>
          <HeadingItem />
          <HeadingItem>Сумма</HeadingItem>
          <HeadingItem>Комиссия</HeadingItem>
          <HeadingItem>Валюта</HeadingItem>
          <HeadingItem>Дата</HeadingItem>
        </SnippetsHeading>
        <SnippetsList
          activePage={0}
          totalPages={0}
          data={list}
          scrollableTarget="scrollableCardContainer"
          textIsEmpty={loading ? 'Загрузка данных...' : 'Нет данных за выбранный период'}
          keyField="docId"
        >
          {(item) => (
            <SnippetsItem onClick={() => handleModalOpen(item.docId)}>
              <ItemIcon icon={IoDocumentText} />
              <ItemLink>
                <b>{item.amount}</b>
              </ItemLink>
              <ItemText>{item.commission}</ItemText>
              <ItemText>{item.currency}</ItemText>
              <ItemText>{getDateTimeReadFormat(item.transDate)}</ItemText>
            </SnippetsItem>
          )}
        </SnippetsList>
      </Snippets>
      <Modal isOpen={transferId} onClose={handleModalClose} title="Платеж">
        <GridField>
          <GridFieldItem label="Сумма" isLoading={false} isReady={true}>
            <b>{transferItem?.amount}</b>
          </GridFieldItem>
          <GridFieldItem label="Комиссия" isLoading={false} isReady={true}>
            {transferItem?.commission}
          </GridFieldItem>
          <GridFieldItem label="Валюта" isLoading={false} isReady={true}>
            {transferItem?.currency}
          </GridFieldItem>
          <GridFieldItem label="ID операции" isLoading={false} isReady={true}>
            {transferItem?.operationId}
          </GridFieldItem>
          <GridFieldItem label="Дата" isReady={true} isLoading={false}>
            {getDateTimeReadFormat(transferItem?.transDate)}
          </GridFieldItem>
          <GridFieldItem isReady={true} isLoading={false}>
            {transferItem?.detail}
          </GridFieldItem>
        </GridField>
      </Modal>
    </>
  )
}
