import React, { useCallback, useEffect, useState } from 'react'
import { Badge, Box, Button, HStack, Stack, Text } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'
import { getUserItem, resetIncomingTransfersList, resetUserItem } from 'store/user.reducer'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { getDateReadFormat, getDateTimeReadFormat } from 'utils/dateFormatting'
import {
  ROLES,
  TYPE_ENABLED_COLOR,
  TYPE_ENABLED_LABEL,
  TYPE_USER_COUNTRY,
  TYPE_USER_PLATFORM,
  TYPE_USER_STATE_COLOR,
  TYPE_USER_STATE_LABEL,
} from 'model/user.model'
import { CountryWidget } from 'components/CountryWidget'
import { getNextPage, getPaymentsListSearch, resetPaymentsList } from 'store/payments.reducer'
import {
  TYPE_PAYMENT_COLOR,
  TYPE_PAYMENT_ICON,
  TYPE_PAYMENT_LABEL,
  TYPE_PAYMENT_STATE_COLOR,
  TYPE_PAYMENT_STATE_LABEL,
} from 'model/payments.model'
import { IncomingTransfers } from './IncomingTransfers/IncomingTransfers'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  GridField,
  GridFieldHeading,
  GridFieldItem,
  RightAside,
} from 'chakra-lib/layout'
import {
  Alert,
  Snippets,
  SnippetsHeading,
  SnippetsItem,
  SnippetsList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from 'chakra-lib'
import { HeadingItem, ItemBadge, ItemIcon, ItemLink, ItemText } from 'chakra-lib/Snippets'
import { ImageSnippets } from 'components/ImageSnippets/ImageSnippets'
import { UploadImageModal } from './UploadImageModal/UploadImageModal'
import { IdentificationsList } from './IdentificationsList/IdentificationsList'
import { ApproveIdentificationModal } from './ApproveIdentificationModal/ApproveIdentificationModal'
import { getAccess, PrivateBox } from 'components/Private/Private'
import { DeclineIdentificationModal } from './DeclineIdentificationModal/DeclineIdentificationModal'
import { EnableUserModal } from './EnableUserModal/EnableUserModal'
import { DisableUserModal } from './DisableUserModal/DisableUserModal'
import { Referral } from './Referral'
// import { EditUserPassport } from './EditUserPassport'
import { useFetchUserBalance } from './services/useFetchUserBalance'

export const UserCard = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const { paramTabIndex } = useParams()
  const user = useSelector((state) => state.authentication.user)
  const isAdmin = getAccess(user.roles, [ROLES.admin])
  const item = useSelector((state) => state.user.item)
  const error = useSelector((state) => state.user.error)
  const loading = useSelector((state) => state.user.loading)
  const activePage = useSelector((state) => state.payments.activePage)
  const totalPages = useSelector((state) => state.payments.totalPages)
  const paymentsList = useSelector((state) => state.payments.list)
  const [tabIndex, setTabIndex] = useState(Number(paramTabIndex) || 0)
  const isReadyItem = !isEmpty(item)
  const handleBack = () => {
    history.goBack()
  }
  const [fetchUserBalance, { isLoading: userBalanceIsLoading, data: balance }] =
    useFetchUserBalance(item.id)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getUserItem(id))
    return () => {
      dispatch(resetUserItem())
      dispatch(resetPaymentsList())
      dispatch(resetIncomingTransfersList())
    }
  }, [])

  useEffect(() => {
    if (isReadyItem && tabIndex === 1) {
      dispatch(
        getPaymentsListSearch({
          payerPhone: item.phone,
        })
      )
    }
  }, [isReadyItem, tabIndex])

  useEffect(() => {
    setTabIndex(Number(paramTabIndex))
  }, [paramTabIndex])

  const handleTabsChange = (index) => {
    history.replace(`/user/card/${index}/${id}`)
  }

  const onShowBalance = useCallback(() => {
    fetchUserBalance()
  }, [])

  return (
    <Container>
      <CardContainer columns="86px 1fr" indentTop={70} onBack={handleBack}>
        {error && (
          <Alert mb="20px" isDisableClose>
            Ошибка загрузки данных
          </Alert>
        )}
        <Tabs
          variant="enclosed-colored"
          colorScheme="orange"
          onChange={handleTabsChange}
          index={tabIndex}
        >
          <TabList>
            <Tab>Данные пользователя</Tab>
            <Tab>Платежи</Tab>
            <Tab>Входящие переводы</Tab>
            <Tab>Документы</Tab>
            <Tab>Идентификация</Tab>
            <Tab>Бонусы</Tab>
            {/*TODO: смена данных, скрыто до востребования (бэк не был готов на момент публикации) */}
            {/*<Tab>Смена данных</Tab>*/}
          </TabList>

          <TabPanels>
            <TabPanel>
              <ContentContainer>
                <ContentContainerColumn>
                  <GridField>
                    <GridFieldItem label="Имя" isLoading={loading} isReady={isReadyItem}>
                      {item?.firstName}
                    </GridFieldItem>
                    <GridFieldItem label="Фамилия" isLoading={loading} isReady={isReadyItem}>
                      {item?.lastName}
                    </GridFieldItem>
                    <GridFieldItem label="Отчество" isLoading={loading} isReady={isReadyItem}>
                      {item?.middleName}
                    </GridFieldItem>
                    <GridFieldItem label="Дата рождения" isLoading={loading} isReady={isReadyItem}>
                      {getDateReadFormat(item?.birthDate)}
                    </GridFieldItem>
                    <GridFieldItem label="ClientID" isLoading={loading} isReady={isReadyItem}>
                      {item?.clientId}
                    </GridFieldItem>
                    <GridFieldItem label="Дата создания" isLoading={loading} isReady={isReadyItem}>
                      {getDateTimeReadFormat(item?.createDate)}
                    </GridFieldItem>
                  </GridField>
                  <GridField>
                    <GridFieldHeading>Паспорт</GridFieldHeading>
                    <GridFieldItem label="Паспорт" isLoading={loading} isReady={isReadyItem}>
                      {item?.passport}
                    </GridFieldItem>
                    <GridFieldItem label="Выдан" isLoading={loading} isReady={isReadyItem}>
                      {item?.issue}
                    </GridFieldItem>
                    <GridFieldItem label="Дата выдачи" isLoading={loading} isReady={isReadyItem}>
                      {getDateReadFormat(item?.dateIssue)}
                    </GridFieldItem>
                  </GridField>
                  <GridField>
                    <GridFieldHeading>Адрес регистрации</GridFieldHeading>
                    <GridFieldItem label="Страна" isLoading={loading} isReady={isReadyItem}>
                      {item?.registrationAddress?.addressFields?.country}
                    </GridFieldItem>
                    <GridFieldItem label="Регион" isLoading={loading} isReady={isReadyItem}>
                      {item?.registrationAddress?.addressFields?.regionName}
                    </GridFieldItem>
                    <GridFieldItem label="Тип региона" isLoading={loading} isReady={isReadyItem}>
                      {item?.registrationAddress?.addressFields?.regionType}
                    </GridFieldItem>
                    <GridFieldItem label="Район" isLoading={loading} isReady={isReadyItem}>
                      {item?.registrationAddress?.addressFields?.districtName}
                    </GridFieldItem>
                    <GridFieldItem
                      label="Населенный пункт"
                      isLoading={loading}
                      isReady={isReadyItem}
                    >
                      {item?.registrationAddress?.addressFields?.settlementName}
                    </GridFieldItem>
                    <GridFieldItem
                      label="Тип населенного пункта"
                      isLoading={loading}
                      isReady={isReadyItem}
                    >
                      {item?.registrationAddress?.addressFields?.settlementType}
                    </GridFieldItem>
                    <GridFieldItem label="Улица" isLoading={loading} isReady={isReadyItem}>
                      {item?.registrationAddress?.addressFields?.streetName}
                    </GridFieldItem>
                    <GridFieldItem label="Тип улицы" isLoading={loading} isReady={isReadyItem}>
                      {item?.registrationAddress?.addressFields?.streetType}
                    </GridFieldItem>
                    <GridFieldItem label="Дом" isLoading={loading} isReady={isReadyItem}>
                      {item?.registrationAddress?.addressFields?.houseNumber}
                    </GridFieldItem>
                    <GridFieldItem label="Номер корпуса" isLoading={loading} isReady={isReadyItem}>
                      {item?.registrationAddress?.addressFields?.block}
                    </GridFieldItem>
                  </GridField>
                  {(item?.inn || item?.snils || item?.ssn) && (
                    <GridField>
                      <GridFieldHeading>Дополнительные документы</GridFieldHeading>
                      {item?.inn && (
                        <GridFieldItem label="ИНН" isLoading={loading} isReady={isReadyItem}>
                          {item?.inn}
                        </GridFieldItem>
                      )}
                      {item?.snils && (
                        <GridFieldItem label="Снилс" isLoading={loading} isReady={isReadyItem}>
                          {item?.snils}
                        </GridFieldItem>
                      )}
                      {item?.ssn && (
                        <GridFieldItem label="TIN" isLoading={loading} isReady={isReadyItem}>
                          {item?.ssn}
                        </GridFieldItem>
                      )}
                    </GridField>
                  )}
                  <GridField>
                    <GridFieldHeading>Идентификация</GridFieldHeading>
                    <GridFieldItem label="Статус" isLoading={loading} isReady={isReadyItem}>
                      {TYPE_USER_STATE_LABEL[item.identification]}
                    </GridFieldItem>
                    {item?.identificationError && (
                      <GridFieldItem label="Код ошибки" isLoading={loading} isReady={isReadyItem}>
                        {item?.identificationError}
                      </GridFieldItem>
                    )}
                    <GridFieldItem
                      label="Дата идентификации"
                      isLoading={loading}
                      isReady={isReadyItem}
                    >
                      {getDateTimeReadFormat(item?.identificationDate)}
                    </GridFieldItem>
                    <GridFieldItem label="Страна" isLoading={loading} isReady={isReadyItem}>
                      <CountryWidget lang={item.identityCountry}>
                        {TYPE_USER_COUNTRY[item.identityCountry]}
                      </CountryWidget>
                    </GridFieldItem>
                  </GridField>
                  <GridField>
                    <GridFieldHeading>Приложение</GridFieldHeading>
                    <GridFieldItem
                      label="Версия приложения"
                      isLoading={loading}
                      isReady={isReadyItem}
                    >
                      {item?.applicationVersion}
                    </GridFieldItem>
                    <GridFieldItem label="Платформа" isLoading={loading} isReady={isReadyItem}>
                      {TYPE_USER_PLATFORM[item.platform]}
                    </GridFieldItem>
                  </GridField>
                  <GridField>
                    <GridFieldHeading>Контакты</GridFieldHeading>
                    <GridFieldItem label="Телефон" isLoading={loading} isReady={isReadyItem}>
                      {item?.phone}
                    </GridFieldItem>
                    <GridFieldItem label="E-mail" isLoading={loading} isReady={isReadyItem}>
                      {item?.email}
                    </GridFieldItem>
                  </GridField>
                </ContentContainerColumn>
                <ContentContainerColumn>
                  <Stack spacing="24px" position="sticky" top="10px">
                    <RightAside>
                      <Text fontSize="0.82rem" color="gray.400" mb={2}>
                        Идентификация
                      </Text>
                      <Badge
                        padding="1px 10px"
                        borderRadius={5}
                        fontSize="1rem"
                        width="100%"
                        textAlign="center"
                        pt={3}
                        pb={3}
                        whiteSpace="none"
                        colorScheme={TYPE_USER_STATE_COLOR[item.identification] || 'gray'}
                      >
                        {TYPE_USER_STATE_LABEL[item.identification] || item.identification}
                      </Badge>
                      {item.identification === 'SUPPORT_REVIEW' && (
                        <PrivateBox role={user.roles} hasAnyRole={[ROLES.admin, ROLES.user]}>
                          <Stack spacing={2} pt="20px">
                            <ApproveIdentificationModal userId={id} />
                            <DeclineIdentificationModal userId={id} />
                          </Stack>
                        </PrivateBox>
                      )}
                    </RightAside>
                    <RightAside>
                      <Text fontSize="0.82rem" color="gray.400" mb={2}>
                        Активность
                      </Text>
                      <Badge
                        padding="1px 10px"
                        borderRadius={5}
                        fontSize="1rem"
                        width="100%"
                        textAlign="center"
                        pt={3}
                        pb={3}
                        whiteSpace="none"
                        colorScheme={TYPE_ENABLED_COLOR[item.enabled] || 'gray'}
                      >
                        {TYPE_ENABLED_LABEL[item.enabled] || item.enabled}
                      </Badge>
                      <PrivateBox role={user.roles} hasAnyRole={[ROLES.admin]}>
                        {!loading && (
                          <Stack spacing={2} pt="20px">
                            {item.enabled ? (
                              <DisableUserModal userId={id} />
                            ) : (
                              <EnableUserModal userId={id} />
                            )}
                          </Stack>
                        )}
                      </PrivateBox>
                    </RightAside>
                    <Box>
                      <Button
                        width="100%"
                        onClick={() => history.push(`/payments/payerPhone=${item.phone}`)}
                        variant="outline"
                      >
                        Отфильтровать в платежах
                      </Button>
                    </Box>
                  </Stack>
                </ContentContainerColumn>
              </ContentContainer>
            </TabPanel>
            <TabPanel>
              <Box width="400px" mb="30px">
                <Box fontSize="14px" mb="10px">
                  <HStack>
                    <Text>Текущий остаток на счете:</Text>
                    <Text fontWeight="bold">{balance?.saldo ?? ' - '}</Text>
                  </HStack>
                  <HStack>
                    <Text>Свободные средства без учета резерва:</Text>
                    <Text fontWeight="bold">{balance?.freeNoShadow ?? ' - '}</Text>
                  </HStack>
                </Box>
                <Button
                  colorScheme="orange"
                  size="sm"
                  onClick={onShowBalance}
                  isLoading={userBalanceIsLoading}
                >
                  Показать баланс
                </Button>
              </Box>
              {isReadyItem && (
                <Snippets
                  columns="42px minmax(150px, 1fr) 180px 180px 220px 200px 200px"
                  //itemWidth="1440px"
                >
                  <SnippetsHeading>
                    <HeadingItem />
                    <HeadingItem>Сумма</HeadingItem>
                    <HeadingItem>Получатель</HeadingItem>
                    <HeadingItem>Дата</HeadingItem>
                    <HeadingItem>Источник</HeadingItem>
                    <HeadingItem>Тип</HeadingItem>
                    <HeadingItem>Статус</HeadingItem>
                  </SnippetsHeading>
                  <SnippetsList
                    activePage={activePage}
                    totalPages={totalPages}
                    data={paymentsList}
                    scrollableTarget="scrollableCardContainer"
                    onNext={() =>
                      dispatch(
                        getNextPage({
                          payerPhone: item.phone,
                        })
                      )
                    }
                  >
                    {(item) => (
                      <SnippetsItem to={`/payments/show/0/${item.id}`}>
                        <ItemIcon
                          icon={TYPE_PAYMENT_ICON[item.paymentType]}
                          color={TYPE_PAYMENT_COLOR[item.paymentType]}
                          boxSize="1.1rem"
                        />
                        <ItemLink>
                          <b>{item.amount}</b>
                          <Badge
                            ml={2}
                            fontSize="0.7rem"
                            fontWeight="500"
                            verticalAlign="middle"
                            colorScheme="gray"
                          >
                            {item.currency}
                          </Badge>
                        </ItemLink>
                        <ItemText>{item.payeePhone}</ItemText>
                        <ItemText>{getDateTimeReadFormat(item.paymentDate)}</ItemText>
                        <ItemText>{item.paymentSource}</ItemText>
                        <ItemText>{TYPE_PAYMENT_LABEL[item.paymentType]}</ItemText>
                        <ItemText>
                          <ItemBadge colorScheme={TYPE_PAYMENT_STATE_COLOR[item.state] || 'gray'}>
                            {TYPE_PAYMENT_STATE_LABEL[item.state] || item.state}
                          </ItemBadge>
                        </ItemText>
                      </SnippetsItem>
                    )}
                  </SnippetsList>
                </Snippets>
              )}
            </TabPanel>
            <TabPanel>
              {isReadyItem && (
                <>
                  <IncomingTransfers />
                </>
              )}
            </TabPanel>
            <TabPanel>
              <Box mb="30px">
                <UploadImageModal userId={id} />
              </Box>
              <ImageSnippets data={item.identityDocumentScans} />
            </TabPanel>
            <TabPanel>
              {isReadyItem && (
                <>
                  <IdentificationsList userId={id} />
                </>
              )}
            </TabPanel>
            <TabPanel>
              {isAdmin ? (
                <>
                  {isReadyItem && (
                    <>
                      <Referral userId={id} phone={item?.phone} />
                    </>
                  )}
                </>
              ) : (
                <Text>Недоступно для пользователя</Text>
              )}
            </TabPanel>
            {/*TODO: смена данных, скрыто до востребования (бэк не был готов на момент публикации) */}
            {/*<TabPanel>*/}
            {/*  <EditUserPassport userId={id} />*/}
            {/*</TabPanel>*/}
          </TabPanels>
        </Tabs>
      </CardContainer>
    </Container>
  )
}
