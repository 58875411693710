import React, { useEffect } from 'react'
import useHistoryListFetch from './services/useHistoryListFetch'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Center,
  Flex,
} from '@chakra-ui/react'
import { getDateTimeReadFormat } from 'chakra-lib/utils/dateFormatting'
import {
  ContentContainer,
  ContentContainerColumn,
  GridField,
  GridFieldHeading,
  GridFieldItem,
} from 'chakra-lib/layout'
import { TYPE_PAYMENT_STATE_LABEL } from 'model/payments.model'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

export const HistoryList = ({ id }) => {
  const item = useSelector((state) => state.payments.item)
  const { list, isLoading, getHistory } = useHistoryListFetch(id)

  useEffect(() => {
    if (!isEmpty(item)) {
      getHistory()
    }
  }, [item])

  if (isEmpty(list)) {
    return (
      <ContentContainer>
        <ContentContainerColumn>
          <Center height="100px">Нет истории</Center>
        </ContentContainerColumn>
      </ContentContainer>
    )
  }

  return (
    <ContentContainer>
      <ContentContainerColumn>
        <Accordion defaultIndex={[0]} allowMultiple>
          {list?.map((item) => (
            <AccordionItem>
              <AccordionButton>
                <Flex flex="1" textAlign="left" minH="46px" alignItems="center">
                  {TYPE_PAYMENT_STATE_LABEL[item.state] || item.state}
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <GridField>
                  <GridFieldHeading>Данные перевода</GridFieldHeading>
                  <GridFieldItem label="Способ" isLoading={isLoading} isReady>
                    {item?.method}
                  </GridFieldItem>
                  <GridFieldItem label="Дата" isLoading={isLoading} isReady>
                    {getDateTimeReadFormat(item?.createDate)}
                  </GridFieldItem>
                </GridField>
                {item?.systemDescription && (
                  <GridField>
                    <GridFieldHeading>Системное сообщение</GridFieldHeading>
                    <GridFieldItem isLoading={isLoading} isReady>
                      {item?.systemDescription}
                    </GridFieldItem>
                  </GridField>
                )}
                {item?.userDescription && (
                  <GridField>
                    <GridFieldHeading>Комментарий пользователя</GridFieldHeading>
                    <GridFieldItem isLoading={isLoading} isReady>
                      {item?.userDescription}
                    </GridFieldItem>
                  </GridField>
                )}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </ContentContainerColumn>
    </ContentContainer>
  )
}
