import React from 'react'
import { applyMiddleware, bindActionCreators, createStore } from 'redux'
import { rootReducer } from 'store'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { refreshLoginAuth, resetAuth } from 'store/authentication.reducer'
import setupAxiosInterceptors from './axios'
import { Provider } from 'react-redux'
import { loadingBarMiddleware } from 'react-redux-loading-bar'

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, promise, loadingBarMiddleware()))
)

const actions = bindActionCreators({ resetAuth, refreshLoginAuth }, store.dispatch)

setupAxiosInterceptors(
  () => actions.resetAuth(),
  () => actions.refreshLoginAuth()
)

export const StoreProvider = ({ children }) => {
  return <Provider store={store}>{children}</Provider>
}
