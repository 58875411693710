import React from 'react'
import { Box, Button, Stack, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { getFilterParams } from 'utils/getFilterParams'
import { getURLFromFilterParams } from 'utils/getURLFromFilterParams'
import { useHistory } from 'react-router-dom'
import { FILTER_USER_PARAMS } from 'config/user.config'
import { FilterFooter, FilterStack, FilterStackGroup } from 'chakra-lib/layout'
import { InputDate, InputSearch, Select } from 'chakra-lib'
import { ExportExcel } from './ExportExcel/ExportExcel'

export const UserFilter = ({ params }) => {
  const {
    clientId,
    firstName,
    lastName,
    middleName,
    passportNumber,
    phone,
    email,
    platform,
    applicationVersion,
    identificationDateFrom,
    identificationDateTo,
    registrationDateFrom,
    identification,
    registrationDateTo,
  } = useSelector((state) => state.user.filter)
  const history = useHistory()
  const countList = useSelector((state) => state.user.totalItems)

  const handleFilter = (param) => {
    const fieldName = Object.keys(param)[0]
    const filedValue = param[fieldName]
    const objFilterParams = getFilterParams(FILTER_USER_PARAMS, params)
    const urlFilterParams = getURLFromFilterParams({
      ...objFilterParams,
      [fieldName]: filedValue,
    })
    history.push(`/user${urlFilterParams}`)
  }

  const handleResetFilter = () => {
    history.push(`/user`)
  }
  return (
    <>
      <FilterStack>
        <InputSearch
          hideIcon
          label="Фамилия"
          name="lastName"
          value={lastName}
          onChange={(e) => handleFilter({ lastName: e })}
        />
        <InputSearch
          hideIcon
          label="Имя"
          name="firstName"
          value={firstName}
          onChange={(e) => handleFilter({ firstName: e })}
        />
        <InputSearch
          hideIcon
          label="Отчество"
          name="middleName"
          value={middleName}
          onChange={(e) => handleFilter({ middleName: e })}
        />
        <InputSearch
          hideIcon
          label="Телефон"
          name="phone"
          value={phone}
          onChange={(e) => handleFilter({ phone: e })}
        />
        <InputSearch
          hideIcon
          label="Email"
          name="email"
          value={email}
          onChange={(e) => handleFilter({ email: e })}
        />
        <InputSearch
          hideIcon
          label="Client ID"
          name="clientId"
          value={clientId}
          onChange={(e) => handleFilter({ clientId: e })}
        />
        <InputSearch
          hideIcon
          label="Номер паспорта"
          name="passportNumber"
          value={passportNumber}
          onChange={(e) => handleFilter({ passportNumber: e })}
        />
        <InputSearch
          hideIcon
          label="Версия"
          name="applicationVersion"
          placeholder="0.0.0"
          value={applicationVersion}
          onChange={(e) => handleFilter({ applicationVersion: e })}
        />
        <Select
          name="identification"
          label="Идентификация"
          isSearchable={false}
          placeholder="Выберите"
          value={identification}
          options={[
            {
              value: '',
              label: 'Все',
            },
            {
              value: 'UNIDENTIFIED',
              label: 'Нет',
            },
            {
              value: 'READY',
              label: 'В ожидании',
            },
            {
              value: 'FULL',
              label: 'Упрощенная идентификация',
            },
            {
              value: 'FULL_DOSSIER',
              label: 'Полная идентификация',
            },
            {
              value: 'FAILED',
              label: 'Ошибка',
            },
            {
              value: 'FAILED_DUE_BLACKLISTED',
              label: 'В черном списке',
            },
            {
              value: 'FAILED_DUE_INVALID_PASSPORT',
              label: 'Невалидный паспорт',
            },
            {
              value: 'SUPPORT_REVIEW',
              label: 'Проверка данных',
            },
          ]}
          onChange={(e) => handleFilter({ identification: e.value })}
        />
        <Select
          name="platform"
          label="Плафторма"
          isSearchable={false}
          placeholder="Выберите"
          value={platform}
          options={[
            {
              value: '',
              label: 'Все',
            },
            {
              value: 'ANDROID',
              label: 'Android',
            },
            {
              value: 'IPHONE',
              label: 'iPhone',
            },
          ]}
          onChange={(e) => handleFilter({ platform: e.value })}
        />
        <FilterStackGroup>
          <InputDate
            value={identificationDateFrom}
            showCurrentDate={false}
            name="identificationDateFrom"
            placement="bottom"
            onChange={(e) => handleFilter({ identificationDateFrom: e })}
            label="Дата идентификации"
            placeholder="C"
          />
          <InputDate
            value={identificationDateTo}
            showCurrentDate={false}
            name="identificationDateTo"
            placement="bottom"
            onChange={(e) => handleFilter({ identificationDateTo: e })}
            placeholder="По"
          />
        </FilterStackGroup>
        <FilterStackGroup>
          <InputDate
            value={registrationDateFrom}
            showCurrentDate={false}
            name="registrationDateFrom"
            placement="bottom"
            onChange={(e) => handleFilter({ registrationDateFrom: e })}
            label="Дата регистрации"
            placeholder="C"
          />
          <InputDate
            value={registrationDateTo}
            showCurrentDate={false}
            name="registrationDateTo"
            placement="bottom"
            onChange={(e) => handleFilter({ registrationDateTo: e })}
            placeholder="По"
          />
        </FilterStackGroup>
      </FilterStack>
      <FilterFooter pb="10px">
        <Stack spacing={2} width="100%">
          <ExportExcel size={countList} />
          <Box>
            <Button size="sm" width="100%" onClick={handleResetFilter}>
              Очистить фильтр
            </Button>
            <Text
              fontSize="0.8rem"
              color="gray.300"
              mt={2}
              textAlign="center"
            >{`Найдено: ${countList}`}</Text>
          </Box>
        </Stack>
      </FilterFooter>
    </>
  )
}
