import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getNextPage, resetDocumentList, resetFilter, updateFilter } from 'store/document.reducer'
import { useParams } from 'react-router-dom'
import { IoPerson } from 'react-icons/io5'
import { getDateTimeReadFormat } from 'utils/dateFormatting'
import { getFilterParams } from 'utils/getFilterParams'
import {
  Container,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside,
} from 'chakra-lib/layout'
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import { HeadingItem, ItemBadge, ItemIcon, ItemLink, ItemText } from 'chakra-lib/Snippets'
import { DocumentFilter } from './DocumentFilter'
import { FILTER_DOCUMENT_PARAMS } from 'config/document.config'

export const DocumentList = () => {
  const { params } = useParams()
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.document.loading)
  const userList = useSelector((state) => state.document.list)
  const totalPages = useSelector((state) => state.document.totalPages)
  const activePage = useSelector((state) => state.document.activePage)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (params !== undefined) {
      const objFilterParams = getFilterParams(FILTER_DOCUMENT_PARAMS, params)
      dispatch(updateFilter(objFilterParams))
    } else {
      dispatch(updateFilter())
    }
  }, [params])

  useEffect(() => {
    return () => {
      dispatch(resetDocumentList())
      dispatch(resetFilter())
    }
  }, [])

  return (
    <Container>
      <SnippetsContainer indentTop={70}>
        <SnippetsContainerMain>
          <Snippets columns="42px minmax(200px, 1fr) 380px 180px 180px" itemWidth="1000px">
            <SnippetsHeading>
              <HeadingItem />
              <HeadingItem>Имя</HeadingItem>
              <HeadingItem>Тип</HeadingItem>
              <HeadingItem>Дата обновления</HeadingItem>
              <HeadingItem>Дата создания</HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              activePage={activePage}
              totalPages={totalPages}
              data={userList}
              onNext={() => dispatch(getNextPage())}
              isLoading={loading}
            >
              {(item) => (
                <SnippetsItem to={`/documents/edit/${item.id}`}>
                  <ItemIcon icon={IoPerson} />
                  <ItemLink>{item?.names[0].text}</ItemLink>
                  <ItemText>
                    <ItemBadge>{item?.type}</ItemBadge>
                  </ItemText>
                  <ItemText>{getDateTimeReadFormat(item?.updateDate)}</ItemText>
                  <ItemText>{getDateTimeReadFormat(item?.createDate)}</ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <DocumentFilter params={params} />
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  )
}
