import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import React, { useEffect } from 'react'
import { Logout } from 'pages/Logout'
import { Login } from 'pages/Login'
import User from './pages/User'
import { checkAuth } from 'store/authentication.reducer'
import './style.css'
import LoadingBar from 'react-redux-loading-bar'
import Payments from './pages/Payments'
import Documents from './pages/Document'
import useMobileDetect from 'use-mobile-detect-hook'
import { PlugMobile } from 'components/PlugMobile/PlugMobile'
import { ContainerApp, Header, NavigationMenuItem } from 'chakra-lib/layout'
import { Logo } from 'components/Logo'
import { Private, PrivateRoute } from 'components/Private'
import { ROLES } from 'model/user.model'
import { Loading } from 'components/Loading'
import Access from './pages/Access'
import { ProfileWidget } from 'components/ProfileWidget'
import ReferralCatalog from './pages/Referral'
import Services from './pages/Services'

function App() {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated)
  const user = useSelector((state) => state.authentication.user)
  const loading = useSelector((state) => state.authentication.loading)
  const detectMobile = useMobileDetect()
  const isMobile = detectMobile.isMobile()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isMobile) {
      document.body.style.overflow = 'hidden'
      document.querySelector('html').style.overflow = 'hidden'
    }
    dispatch(checkAuth())
  }, [])

  if (isMobile) {
    return <PlugMobile />
  }

  return (
    <>
      <LoadingBar updateTime={10} style={{ backgroundColor: '#F27329', height: '2px' }} />
      <ContainerApp>
        {isAuthenticated ? (
          !loading ? (
            <>
              <Header
                renderLogo={<Logo />}
                renderNavigationItem={
                  <>
                    <Private role={user.roles} hasAnyRole={[ROLES.user, ROLES.admin]}>
                      <NavigationMenuItem to="/user" colorScheme="orange">
                        Пользователи
                      </NavigationMenuItem>
                    </Private>
                    <Private role={user.roles} hasAnyRole={[ROLES.user, ROLES.admin]}>
                      <NavigationMenuItem to="/payments" colorScheme="orange">
                        Платежи
                      </NavigationMenuItem>
                    </Private>
                    <Private role={user.roles} hasAnyRole={[ROLES.admin]}>
                      <NavigationMenuItem to="/documents" colorScheme="orange">
                        Документы
                      </NavigationMenuItem>
                    </Private>
                    <Private role={user.roles} hasAnyRole={[ROLES.admin]}>
                      <NavigationMenuItem to="/services/banners" colorScheme="orange">
                        Сервисы
                      </NavigationMenuItem>
                    </Private>
                    <Private role={user.roles} hasAnyRole={[ROLES.admin]}>
                      <NavigationMenuItem to="/referral/catalog" colorScheme="orange">
                        Бонусы
                      </NavigationMenuItem>
                    </Private>
                    <Private role={user.roles} hasAnyRole={[ROLES.admin]}>
                      <NavigationMenuItem to="/access" colorScheme="orange">
                        Доступ
                      </NavigationMenuItem>
                    </Private>
                  </>
                }
                renderProfileWidget={<ProfileWidget />}
              />
              <Switch>
                <PrivateRoute path="/user" role={user.roles} hasAnyRole={[ROLES.user, ROLES.admin]}>
                  <User />
                </PrivateRoute>
                <PrivateRoute
                  path="/payments"
                  role={user.roles}
                  hasAnyRole={[ROLES.user, ROLES.admin]}
                >
                  <Payments />
                </PrivateRoute>
                <PrivateRoute path="/documents" role={user.roles} hasAnyRole={[ROLES.admin]}>
                  <Documents />
                </PrivateRoute>
                <PrivateRoute path="/access" role={user.roles} hasAnyRole={[ROLES.admin]}>
                  <Access />
                </PrivateRoute>
                <PrivateRoute path="/referral" role={user.roles} hasAnyRole={[ROLES.admin]}>
                  <ReferralCatalog />
                </PrivateRoute>
                <PrivateRoute path="/services" role={user.roles} hasAnyRole={[ROLES.admin]}>
                  <Services />
                </PrivateRoute>
                <Route path="/">
                  <Redirect to="/user" />
                </Route>
              </Switch>
            </>
          ) : (
            <Loading />
          )
        ) : (
          <Login />
        )}
        <Route path="/logout">
          <Logout />
        </Route>
      </ContainerApp>
    </>
  )
}

export default App
