import axios from 'axios'
import { API_URL, SERVER } from 'config/api'

const ENTITY = 'documents'

export const documents = Object.freeze({
  async deleteItem(id) {
    const response = await axios.delete(`${SERVER}${API_URL}/${ENTITY}/${id}`)
    return response.data
  },
})
