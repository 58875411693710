import axios from 'axios'
import { API_REFERRAL_URL, SERVER } from 'config/api'

export const referral = Object.freeze({
  async fetchCatalog() {
    const response = await axios.get(`${SERVER}${API_REFERRAL_URL}/catalog/admin`)
    return response.data
  },
  async fetchTransfer(data) {
    const response = await axios.post(`${SERVER}${API_REFERRAL_URL}/transfer/admin/search`, data)
    return response.data
  },
  async fetchRef(id) {
    const response = await axios.get(`${SERVER}${API_REFERRAL_URL}/ref/admin/${id}`)
    return response.data
  },
  async fetchTransferCustomer(id) {
    const response = await axios.get(`${SERVER}${API_REFERRAL_URL}/transfer/admin/${id}`)
    return response.data
  },
  async fetchTransferCustomerSearch(id) {
    const response = await axios.get(`${SERVER}${API_REFERRAL_URL}/transfer/admin/${id}`)
    return response.data
  },
  async process(id) {
    const response = await axios.put(`${SERVER}${API_REFERRAL_URL}/transfer/admin/${id}/process`)
    return response.data
  },
  async refund(id) {
    const response = await axios.put(`${SERVER}${API_REFERRAL_URL}/transfer/admin/${id}/refund`)
    return response.data
  },
  async fetchCatalogItem(id) {
    const response = await axios.get(`${SERVER}${API_REFERRAL_URL}/catalog/admin/${id}`)
    return response.data
  },
  async createCatalogItem(data) {
    const response = await axios.post(`${SERVER}${API_REFERRAL_URL}/catalog/admin/add`, [data])
    return response.data
  },
  async updateCatalogItem(id, data) {
    const response = await axios.put(`${SERVER}${API_REFERRAL_URL}/catalog/admin/${id}`, data)
    return response.data
  },
  async deleteCatalogItem(id) {
    const response = await axios.delete(`${SERVER}${API_REFERRAL_URL}/catalog/admin/remove/${id}`)
    return response.data
  },
  async fetchCustomerItem(id) {
    const response = await axios.get(`${SERVER}${API_REFERRAL_URL}/customer/admin/${id}`)
    return response.data
  },
  async fetchEvents(id) {
    const response = await axios.get(`${SERVER}${API_REFERRAL_URL}/events/admin/view/${id}`)
    return response.data
  },
  async fetchSettings() {
    const response = await axios.get(`${SERVER}${API_REFERRAL_URL}/settings/admin`)
    return response.data
  },
  async updateSettings({ id, data }) {
    const response = await axios.put(`${SERVER}${API_REFERRAL_URL}/settings/admin/${id}`, data)
    return response.data
  },
  async fetchCustomerRelatedBy(id) {
    const response = await axios.get(`${SERVER}${API_REFERRAL_URL}/customer/admin/relatedBy/${id}`)
    return response.data
  },
})
