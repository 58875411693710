import axios from 'axios'
import { API_URL, SERVER } from 'config/api'

const ENTITY = 'users'

export const users = Object.freeze({
  async fetchUserList(data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/search`, data)
    return response.data
  },
  async setUserEnable(id) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/${id}/enable`)
    return response.data
  },
  async setUserDisable(id) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/${id}/disable`)
    return response.data
  },
  async updateUser(id, data) {
    const response = await axios.put(`${SERVER}${API_URL}/${ENTITY}/${id}`, data)
    return response.data
  },
  async fetchUserBalance(id, data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/${id}/balance`, data)
    return response.data
  },
})
