import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getDateTimeReadFormat } from 'utils/dateFormatting'
import {
  TYPE_PAYMENT_COLOR,
  TYPE_PAYMENT_ICON,
  TYPE_PAYMENT_LABEL,
  TYPE_PAYMENT_SOURCE_LABEL,
  TYPE_PAYMENT_STATE_COLOR,
  TYPE_PAYMENT_STATE_LABEL,
} from 'model/payments.model'
import {
  clearFilter,
  getNextPage,
  resetFilter,
  resetPaymentsList,
  updateFilter,
} from 'store/payments.reducer'
import { PaymentsFilter } from './PaymentsFilter'
import { getFilterParams } from 'utils/getFilterParams'
import { FILTER_PAYMENTS_PARAMS } from 'config/payments.config'
import {
  Container,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside,
} from 'chakra-lib/layout'
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import { HeadingItem, ItemBadge, ItemIcon, ItemLink, ItemText } from 'chakra-lib/Snippets'
import { Badge } from '@chakra-ui/react'

export const Payments = () => {
  const { params } = useParams()
  const dispatch = useDispatch()
  const paymentsList = useSelector((state) => state.payments.list)
  const totalPages = useSelector((state) => state.payments.totalPages)
  const activePage = useSelector((state) => state.payments.activePage)

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (params !== undefined) {
      const objFilterParams = getFilterParams(FILTER_PAYMENTS_PARAMS, params)
      dispatch(updateFilter(objFilterParams))
    } else {
      dispatch(clearFilter())
    }
  }, [params])

  useEffect(() => {
    return () => {
      dispatch(resetPaymentsList())
      dispatch(resetFilter())
    }
  }, [])

  return (
    <Container>
      <SnippetsContainer indentTop={70}>
        <SnippetsContainerMain>
          <Snippets
            columns="42px minmax(170px, 1fr) 190px 210px 200px 190px 180px 180px 180px 180px 200px 200px 300px"
            itemWidth="2600px"
          >
            <SnippetsHeading position="sticky" top="0" zIndex={3}>
              <HeadingItem />
              <HeadingItem>Сумма</HeadingItem>
              <HeadingItem>Сумма комиссии</HeadingItem>
              <HeadingItem>Сумма к выдаче получателю</HeadingItem>
              <HeadingItem>ФИО получателя</HeadingItem>
              <HeadingItem>Карта</HeadingItem>
              <HeadingItem>Получатель</HeadingItem>
              <HeadingItem>Отправитель</HeadingItem>
              <HeadingItem>Дата</HeadingItem>
              <HeadingItem>Тип источника</HeadingItem>
              <HeadingItem>Источник</HeadingItem>
              <HeadingItem>Тип</HeadingItem>
              <HeadingItem>Статус</HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              activePage={activePage}
              totalPages={totalPages}
              data={paymentsList}
              onNext={() => dispatch(getNextPage())}
            >
              {(item) => (
                <SnippetsItem to={`/payments/show/0/${item.id}`}>
                  <ItemIcon
                    icon={TYPE_PAYMENT_ICON[item.paymentType]}
                    color={TYPE_PAYMENT_COLOR[item.paymentType]}
                    boxSize="1.1rem"
                  />
                  <ItemLink>
                    <b>{item.amount}</b>
                    <Badge
                      ml={2}
                      fontSize="0.7rem"
                      fontWeight="500"
                      verticalAlign="middle"
                      colorScheme="gray"
                    >
                      {item.currency}
                    </Badge>
                  </ItemLink>
                  <ItemText>
                    <b>{item.commission}</b>
                    <Badge
                      ml={2}
                      fontSize="0.7rem"
                      fontWeight="500"
                      verticalAlign="middle"
                      colorScheme="gray"
                    >
                      {item.commissionCurrency}
                    </Badge>
                  </ItemText>
                  <ItemText>
                    <b>{item.destinationAmount}</b>
                    <Badge
                      ml={2}
                      fontSize="0.7rem"
                      fontWeight="500"
                      verticalAlign="middle"
                      colorScheme="gray"
                    >
                      {item.destinationCurrency}
                    </Badge>
                  </ItemText>
                  <ItemText>{item.payeeFIO}</ItemText>
                  <ItemText>{item.paymentDestination}</ItemText>
                  <ItemText>{item.payeePhone}</ItemText>
                  <ItemText>{item.payerPhone}</ItemText>
                  <ItemText>{getDateTimeReadFormat(item.paymentDate)}</ItemText>
                  <ItemText>{TYPE_PAYMENT_SOURCE_LABEL[item.paymentSourceType]}</ItemText>
                  <ItemText>
                    {item.paymentSource
                      ? item.paymentSource
                      : item.paymentType === 'WITHDRAW_TO_CARD'
                      ? 'Внутренний счёт'
                      : item.paymentSource}
                  </ItemText>
                  <ItemText>{TYPE_PAYMENT_LABEL[item.paymentType]}</ItemText>
                  <ItemText>
                    <ItemBadge colorScheme={TYPE_PAYMENT_STATE_COLOR[item.state] || 'gray'}>
                      {TYPE_PAYMENT_STATE_LABEL[item.state] || item.state}
                    </ItemBadge>
                  </ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <PaymentsFilter params={params} />
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  )
}
