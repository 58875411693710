import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form,
  FormSection,
} from 'chakra-lib/layout'
import { Field, Formik } from 'formik'
import {Button, HStack, Text, Tooltip} from '@chakra-ui/react'
import useFetchSettings from './services/useFetchSettings'
import useUpdateSettings from './services/useUpdateSettings'
import { SwitchForm } from 'components/SwitchForm'
import { InfoOutlineIcon } from '@chakra-ui/icons'

export const DeactivationModules = () => {
  const { dataSettings, isLoadingFetchSettings } = useFetchSettings()
  const { updateSettings, isLoadingUpdate } = useUpdateSettings()

  const isLoading = isLoadingFetchSettings || isLoadingUpdate

  const handleForm = async (values) => {
    const dataRequest = {
      referralHidden: values?.referralHidden ? 1 : 0,
    }
    await updateSettings(dataRequest)
  }

  return (
    <Container>
      <CardContainer indentTop={120} isHideBack>
        <ContentContainer>
          <ContentContainerColumn>
            <Formik
              initialValues={{
                referralHidden: dataSettings?.referralHidden === 1,
              }}
              enableReinitialize={true}
              onSubmit={handleForm}
            >
              {(props) => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button type="submit" colorScheme="orange" isLoading={isLoading}>
                      Обновить
                    </Button>
                  }
                >
                  <FormSection>
                    <Field
                      size="lg"
                      name="referralHidden"
                      component={SwitchForm}
                      label={
                        <HStack>
                          <Text>Скрыть модуль реферальной системы</Text>
                          <Tooltip label="Включенное (активное) положение бегунка означает отключение модуля в мобильном приложении">
                            <InfoOutlineIcon cursor="pointer" color="blue.500" />
                          </Tooltip>
                        </HStack>
                      }
                      isDisabled={isLoading}
                    />
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}
