import {
  Container,
  FilterHeader,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside,
} from 'chakra-lib/layout'
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import {
  HeadingItem,
  ItemBadge,
  ItemIcon,
  ItemLink,
  ItemText,
} from 'chakra-lib/Snippets'
import React from 'react'
import { truncate } from 'lodash'
import useFetchBanners from './services/useFetchBanners'
import { IoMdAlert } from 'react-icons/io'
import { getDateTimeReadFormat } from 'utils/dateFormatting'
import { Button } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

export const List = () => {
  const history = useHistory()
  const { data } = useFetchBanners()

  return (
    <Container>
      <SnippetsContainer indentTop={70}>
        <SnippetsContainerMain>
          <Snippets
            columns="42px minmax(170px, 1fr) minmax(280px, 1fr) 210px 210px 210px"
            itemWidth="1600px"
          >
            <SnippetsHeading position="sticky" top="0" zIndex={3}>
              <HeadingItem />
              <HeadingItem>Тема</HeadingItem>
              <HeadingItem>Сообщение</HeadingItem>
              <HeadingItem>Активность</HeadingItem>
              <HeadingItem>Старт</HeadingItem>
              <HeadingItem>Финиш</HeadingItem>
            </SnippetsHeading>
            <SnippetsList activePage={0} totalPages={0} data={data}>
              {(item) => (
                <SnippetsItem to={`/services/banners/show/${item.id}`}>
                  <ItemIcon icon={IoMdAlert} />
                  <ItemLink>{item?.subject}</ItemLink>
                  <ItemText>{truncate(item?.body, { length: 200 })}</ItemText>
                  <ItemText>
                    <ItemBadge colorScheme={item.isActive === 1 ? 'green' : 'gray'}>
                      {item.isActive === 1 ? 'Активно' : 'Неактивно'}
                    </ItemBadge>
                  </ItemText>
                  <ItemText>{getDateTimeReadFormat(item?.start)}</ItemText>
                  <ItemText>{getDateTimeReadFormat(item?.end)}</ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <FilterHeader>
            <Button
              width="100%"
              onClick={() => history.push(`/services/banners/new`)}
              colorScheme="orange"
            >
              Создать
            </Button>
          </FilterHeader>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  )
}
