import React from 'react'
import { Box, Button, Stack, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { getFilterParams } from 'utils/getFilterParams'
import { getURLFromFilterParams } from 'utils/getURLFromFilterParams'
import { useHistory } from 'react-router-dom'
import { TYPE_PAYMENT_STATE_LABEL } from 'model/payments.model'
import { FILTER_PAYMENTS_PARAMS } from 'config/payments.config'
import { FilterFooter, FilterStack, FilterStackGroup } from 'chakra-lib/layout'
import { InputDate, InputSearch, Select } from 'chakra-lib'
import { InputCardSearch } from 'components/InputCardSearch/InputCardSearch'
import { ExportExcel } from './ExportExcel/ExportExcel'

export const PaymentsFilter = ({ params }) => {
  const {
    payeePhone,
    payerPhone,
    paymentAmountMax,
    paymentAmountMin,
    paymentDateFrom,
    paymentDateTo,
    paymentId,
    paymentState,
    payeeCardNumber,
    payeeFIO,
  } = useSelector((state) => state.payments.filter)
  const history = useHistory()
  const paymentsTotal = useSelector((state) => state.payments.totalItems)

  const handleFilter = (param) => {
    const fieldName = Object.keys(param)[0]
    const filedValue = param[fieldName]
    const objFilterParams = getFilterParams(FILTER_PAYMENTS_PARAMS, params)
    const urlFilterParams = getURLFromFilterParams({
      ...objFilterParams,
      [fieldName]: filedValue,
    })
    history.push(`/payments${urlFilterParams}`)
  }

  const handleResetFilter = () => {
    history.push(`/payments`)
  }

  return (
    <>
      <FilterStack>
        <InputSearch
          hideIcon
          label="ФИО получателя"
          name="payeeFIO"
          value={payeeFIO}
          onChange={(e) => handleFilter({ payeeFIO: e })}
        />
        <InputCardSearch
          hideIcon
          label="Номер карточки получателя"
          name="payeeCardNumber"
          value={payeeCardNumber}
          onChange={(e) => handleFilter({ payeeCardNumber: e })}
        />
        <InputSearch
          hideIcon
          label="Телефон получателя"
          name="payeePhone"
          value={payeePhone}
          onChange={(e) => handleFilter({ payeePhone: e })}
        />
        <InputSearch
          hideIcon
          label="Телефон отправителя"
          name="payerPhone"
          value={payerPhone}
          onChange={(e) => handleFilter({ payerPhone: e })}
        />
        <InputSearch
          hideIcon
          label="Макс. сумма платежа"
          name="paymentAmountMax"
          value={paymentAmountMax}
          onChange={(e) => handleFilter({ paymentAmountMax: e })}
        />
        <InputSearch
          hideIcon
          label="Мин. сумма платежа"
          name="paymentAmountMin"
          value={paymentAmountMin}
          onChange={(e) => handleFilter({ paymentAmountMin: e })}
        />
        <InputSearch
          hideIcon
          label="Payment ID"
          name="paymentId"
          value={paymentId}
          onChange={(e) => handleFilter({ paymentId: e })}
        />
        <Select
          name="paymentState"
          label="Статус"
          isSearchable={false}
          placeholder="Выберите"
          value={paymentState}
          options={[
            {
              value: '',
              label: 'Все',
            },
            ...Object.entries(TYPE_PAYMENT_STATE_LABEL).map(([id, field]) => ({
              value: id,
              label: field,
            })),
          ]}
          onChange={(e) => handleFilter({ paymentState: e.value })}
        />
        {/*<Select
         name="paymentState"
         label="Источник"
         isSearchable={false}
         placeholder="Выберите"
         value={paymentState}
         options={[
           {
             value: '',
             label: 'Все'
           },
           ...Object.entries(TYPE_PAYMENT_SOURCE_LABEL).map(([id, field]) => ({
             value: id,
             label: field
           }))
         ]}
         onChange={e => handleFilter({ paymentState: e.value })}
       />*/}
        <FilterStackGroup>
          <InputDate
            value={paymentDateFrom}
            showCurrentDate={false}
            name="paymentDateFrom"
            placement="bottom"
            onChange={(e) => handleFilter({ paymentDateFrom: e })}
            label="Дата платежа"
            placeholder="C"
          />
          <InputDate
            value={paymentDateTo}
            showCurrentDate={false}
            name="paymentDateTo"
            placement="bottom"
            onChange={(e) => handleFilter({ paymentDateTo: e })}
            placeholder="По"
          />
        </FilterStackGroup>
      </FilterStack>
      <FilterFooter pb="10px">
        <Stack spacing={2} width="100%">
          <ExportExcel size={paymentsTotal} />
          <Box>
            <Button size="sm" width="100%" onClick={handleResetFilter}>
              Очистить фильтр
            </Button>
            <Text
              fontSize="0.8rem"
              color="gray.300"
              mt={2}
              textAlign="center"
            >{`Найдено: ${paymentsTotal}`}</Text>
          </Box>
        </Stack>
      </FilterFooter>
    </>
  )
}
