import React from 'react'
import { Box } from '@chakra-ui/react'
import { FormFooter } from './FormFooter'

export const Form = ({ dataForm, footer, children, onSubmit, ...props }) => {
  const isError = dataForm ? !dataForm.isValid && dataForm.submitCount > 0 : false
  return (
    <Box {...props}>
      <form onSubmit={onSubmit}>
        {children}
        {footer && <FormFooter children={footer} isError={isError} />}
      </form>
    </Box>
  )
}
