import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import useQuery from 'components/useQuery'
import { checkParam } from 'utils/checkParam'
import { getDateTimeReadFormat, getDateTimeToSubmit } from 'utils/dateFormatting'
import { api } from 'api'
import { exportExcelFile } from 'utils/exportExcelFile'
import { TYPE_PAYMENT_LABEL, TYPE_PAYMENT_STATE_LABEL } from 'model/payments.model'
import { columns } from './columns'
import JSONbig from 'json-bigint'

const JSONBigString = JSONbig({
  strict: false,
  storeAsString: true,
})

export const useFetchData = (size) => {
  const toast = useToast()
  const filter = useSelector((state) => state.payments.filter)

  const { isError, isLoading, isSuccess, response, request } = useQuery((e) =>
    api.payments.fetchPaymentsList(e)
  )

  const getData = async () => {
    const dataRequest = {
      size,
      page: 0,
      payeeFIO: checkParam(filter.payeeFIO),
      payeeCardNumber: checkParam(filter.payeeCardNumber),
      payeePhone: checkParam(filter.payeePhone),
      payerPhone: checkParam(filter.payerPhone),
      paymentAmountMax: checkParam(filter.paymentAmountMax),
      paymentAmountMin: checkParam(filter.paymentAmountMin),
      paymentDateFrom: checkParam(getDateTimeToSubmit(filter.paymentDateFrom)),
      paymentDateTo: checkParam(getDateTimeToSubmit(filter.paymentDateTo)),
      paymentId: checkParam(filter.paymentId),
      paymentState: checkParam(filter.paymentState),
    }
    await request(dataRequest)
  }

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      const res = JSONBigString.parse(response)
      const _data = res?.payments?.map((i) => ({
        ...i,
        paymentType: TYPE_PAYMENT_LABEL[i.paymentType],
        paymentDate: getDateTimeReadFormat(i.paymentDate),
        paymentSource: i.paymentSource
          ? i.paymentSource
          : i.paymentType === 'WITHDRAW_TO_CARD'
          ? 'Внутренний счёт'
          : i.paymentSource,
        state: TYPE_PAYMENT_STATE_LABEL[i.state] || i.state,
      }))
      exportExcelFile(_data, {
        sheetName: 'Payments',
        columns,
      })
    }
  }, [isLoading])

  return {
    data: response,
    isLoading,
    getData,
  }
}
